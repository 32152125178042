import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getAuth, getAuthBusy, getUser, getUserData, setAuthBusy } from '../app/reducer/auth.reducer';
import { HSeparator } from "components/separator/Separator";
import { Flex, Icon, Box, Text, Button, Image, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, useColorMode, useColorModeValue, Alert, AlertIcon, AlertTitle, CloseButton, useToast } from '@chakra-ui/react';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import Footer from 'components/footer/FooterAdmin';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { loginWithEmailPassword, loginWithPhone, loginWithPhoneOTP } from 'app/api/firebase';
import { unwrapResult } from '@reduxjs/toolkit';
import Lottie from "lottie-react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, FreeMode, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/free-mode";
import 'swiper/css/pagination';


import animation_conveyor from "assets/lottie/animation_conveyor.json";
import animation_1 from "assets/lottie/1.json";
import animation_2 from "assets/lottie/2.json";
import animation_3 from "assets/lottie/3.json";
import animation_4 from "assets/lottie/4.json";
import animation_5 from "assets/lottie/5.json";

import { getAllStoreAddresses, getAllSupplierAddresses, getAllCustomerAddresses, getAllSKU } from 'app/reducer/general.reducer';
import { getAllHyperlocalOrders } from 'app/reducer/hyperlocal.reducer';
import { getAllPanIndiaOrders } from 'app/reducer/panindia.reducer';
import { getAllTransactions } from 'app/reducer/wallet.reducer';

import logo from 'assets/img/shipmantra_business_logo.svg';
import logowhite from 'assets/img/shipmantra_business_logo_white.svg';
import landingImage from 'assets/img/Pitch_Deck.png';
 
import Card from 'components/card/Card';
import { PHONE_REGEX } from 'utils/validation';
import { checkNumberRegisteredAPI } from 'app/api/auth';
import { sendBusinessEnquiryAPI } from 'app/api/general';

SwiperCore.use([Autoplay,FreeMode,Pagination]);

const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const slidesArr = [
  {
    animation: animation_1,
    title: "Unified Order Management",
    subTitle: "Efficiently handle all your orders through a single, centralized dashboard.",
  },
  {
    animation: animation_2,
    title: "Customer & Store Management",
    subTitle: "Streamline your store and customer management activities, all within a unified platform.",
  },
  {
    animation: animation_3,
    title: "Compare and Book",
    subTitle: "Effortlessly compare shipping options and book with the most suitable choice.",
  },
  {
    animation: animation_4,
    title: "Tailored to Your Business",
    subTitle: "Customize pricing and invoices according to your business requirements.",
  },
  {
    animation: animation_5,
    title: "Quick and Simple Setup",
    subTitle: "Experience a swift and hassle-free setup process, allowing you to get started within minutes.",
  },
]

export default function Auth() {
	const { colorMode, toggleColorMode } = useColorMode();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
	const bgColor = useColorModeValue('white', '#111c44');
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
	const shadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'none');

  const [searchParams] = useSearchParams();
  const isAuthBusy = useAppSelector(getAuthBusy);
  const isAuth = useAppSelector(getAuth);
  const userData = useAppSelector(getUser);
  const [busy, setBusy] = React.useState(false);

  const [regMode, setRegMode] = React.useState<'sign-in'|'register'>('sign-in');
  const [loginMode, setLoginMode] = React.useState<'mobile-phone'|'mobile-submitted'|'mobile-otp'|'email'>('mobile-phone');

  const [phone, setPhone] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [phoneErr, setPhoneErr] = React.useState(false);
  const [otpErr, setOtpErr] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('Email or password entered is incorrect');

  const [company, setCompany] = React.useState('');
  const [volume, setVolume] = React.useState('');
  const [requested, setRequested] = React.useState(false);

  const [newUserErr, setNewUserErr] = React.useState(false);

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {

    if (swiper !== null)
        swiper.slideTo(activeSlide + 1);

  }, [activeSlide])

  const handleMouseEnter = () => {
      if (swiper) swiper.autoplay.stop();
  };

  const handleMouseLeave = () => {
      if (swiper) swiper.autoplay.start();
  };

  useEffect(() => {

    if(isAuth===true){
      navigate(`/`);
      // navigate(`/operator-pickups-and-deliveries`);
    }


  }, [isAuth])

  const login = () => {
    if(EMAIL_REGEX.test(email)){
      dispatch(setAuthBusy(true));
      loginWithEmailPassword(email,password).then((res:any)=>{
        // console.log(res);
        dispatch(getUserData())
        .then(unwrapResult).then(()=>{
          dispatch(getAllTransactions());
          dispatch(getAllStoreAddresses({page:1, limit:20}));
          dispatch(getAllSupplierAddresses({page:1, limit:20}));
          dispatch(getAllCustomerAddresses({page:1, limit:20}));
          dispatch(getAllSKU({page:1, limit:20}));
  
          // dispatch(getAllHyperlocalOrders({page: 1, limit: 10}));
          // dispatch(getAllPanIndiaOrders({page: 1, limit: 10}));
          const redirectURL = searchParams.get('redirect');
          if(redirectURL!==null){

            navigate(redirectURL);
            
          }
        }).catch(()=>{ 
          setNewUserErr(true);
        });

      }).catch((err)=>{
        setErrorText('Email or password entered is incorrect');
        setShowError(true);
      }).finally(()=>{
        dispatch(setAuthBusy(false));
      })
    }else{
      setErrorText('Please enter valid Email');
      setShowError(true);
    }

  }

  const sendOTP = () => {

    if (PHONE_REGEX.test(phone)) {
      setBusy(true);
      setLoginMode('mobile-submitted');

      checkNumberRegisteredAPI("+91"+phone)
      .then(()=>{
        //number registered
        loginWithPhone("+91" + phone).then(async () => {
          // alert('changed to otp')
          setLoginMode('mobile-otp');
          setBusy(false);
        }
        ).catch((err) => {
          // alert('some err' + err.toString())
          console.log("LOGIN ERR: ", err);
          setBusy(false);
          setPhoneErr(true)
          setLoginMode('mobile-phone');
        })
      })
      .catch(()=>{
        //number not registered
        setBusy(false);
        setNewUserErr(true);
        return
      })
      // loginWithPhone(countryCode + phone).then(async () => {
      // checkBlockedUser("+" + phone).then(() => {
      // })
      // .catch(error => {
      //   // alert(error.toString())
      //   if (error.error === 403) {
      //     dispatch(setBlocked(true))
      //     setBusy(false)
      //     handleClose()
      //     navigate('/')
      //   }
      // })
      // loginWithPhone("+" + phone).then(async () => {
      //   setSignInState('otp');
      //   setBusy(false);
      // }
      // ).catch((err) => {
      //   console.log("LOGIN ERR: ", err);
      //   setBusy(false);
      //   setPhoneErr(true)
      //   setSignInState('number');
      // })
    } else {
      setPhoneErr(true);
    }
  }

  const submitOTP = () => {
    if (otp.length === 6) {
      setBusy(true);
      loginWithPhoneOTP(otp).then((res:any)=>{
        // console.log(res);
        dispatch(getUserData())
        .then(unwrapResult).then(()=>{
          dispatch(getAllTransactions());
          dispatch(getAllStoreAddresses({page:1, limit:20}));
          dispatch(getAllSupplierAddresses({page:1, limit:20}));
          dispatch(getAllCustomerAddresses({page:1, limit:20}));
          dispatch(getAllSKU({page:1, limit:20}));
  
          // dispatch(getAllHyperlocalOrders({page: 1, limit: 10}));
          // dispatch(getAllPanIndiaOrders({page: 1, limit: 10}));
          const redirectURL = searchParams.get('redirect');
          if(redirectURL!==null){

            navigate(redirectURL);
            
          }
        }).catch(()=>{ 
          setNewUserErr(true);
          setBusy(false);
        });

      }).catch((err) => {
        console.log("LOGIN ERR: ", err);
        setBusy(false);
        setOtpErr(true);
      }
      )
    } else {
      setOtpErr(true);
    }
  }

  const toast = useToast()

  const sendRequest = () => {
    if(requested){
      return
    }

    if (PHONE_REGEX.test(phone)) {

      const payload = {
        companyName: company,
        phone: phone,
        businessVolume: volume
      }

      sendBusinessEnquiryAPI(payload)
      .then(()=>setRequested(true))
      .catch((e)=>{
        if(e.error===406){
          toast({
            title: 'We have already recieved your request.',
            description: e.data.message as string+"We will get in touch with you soon",
            status: 'warning',
            position: 'bottom-right',
            isClosable: true,
          })
        }else{
          toast({
            title: 'Could not send request.',
            description: e.data.message as string+". Please check your inputs and try again.",
            status: 'error',
            position: 'bottom-right',
            isClosable: true,
          })
        }
      })

    }else{
      setPhoneErr(true);
    }
  }

  useEffect(() => {

    if (otp.length === 6 && busy === false) {
      submitOTP()
    }

  }, [otp])
  

	return (
    <>
		<Flex direction='column' alignItems='center' justifyContent='center' position='relative' h='100vh'>
			<Flex
        as={Card}
        boxShadow={shadow}
				h={{
					sm: 'initial',
					md: 'unset'
				}}
        minH='80vh'
				w={{ sm: 'auto', lg: '100%' }}
				maxW={{ md: '66%', lg: '1313px' }}
				m='10px'
				p={{ md: '10px', lg: '0px' }}
				justifyContent='start'
				direction='column'
        >
          {regMode==='register'?
            <Flex
              maxW={{ base: "100%", md: "500px" }}
              w={{ base: "100%", md: "max-content" }}
              mx={{ base: "auto", lg: "0px" }}
              h='100%'
              alignItems='start'
              justifyContent='start'
              mb={{ base: "30px", md: "40px" }}
              px={{ base: "0px", md: "20px" }}
              // ps={{ base: "initial", md: "70px" }}
              mt={{ base: "30px", md: "40px" }}
              flexDirection='column'
              >
                <Box
                  h='55px'
                >
                  <Image 
                    src={(colorMode==='light')?logo:logowhite} width='100%'
                  />
                  <Text
                    color="#7950f2" fontWeight={600} fontSize='20px' marginTop='3px' textAlign='center'
                  >The right fuel for your business growth</Text>
                </Box>
                <Box w='100%' flex={1} display='flex' flexDir='column' justifyContent='center'>
                  <Heading color={textColor} fontSize='36px' mb='10px'>
                    Register
                  </Heading>
                  <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                  >
                    
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Phone Number<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        bgColor={bgColor}
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        onWheel={(e)=>e.currentTarget.blur()}
                        disabled={busy}
                        type='number'
                        placeholder='Mobile Number'
                        maxLength={10}
                        mb='10px'
                        fontWeight='500'
                        size='lg'
                        value={phone}
                        onChange={(e)=>{if(e.target.value.length<11)setPhone(e.target.value)}}
                        onClick={()=>setShowError(false)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Company/Business Name<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        bgColor={bgColor}
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        placeholder='Company/Business Name'
                        mb='10px'
                        fontWeight='500'
                        value={company}
                        onChange={(e)=>setCompany(e.target.value)}
                        size='lg'
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Volume of business (Parcels per month)<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        bgColor={bgColor}
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='number'
                        placeholder='500'
                        mb='10px'
                        fontWeight='500'
                        size='lg'
                        value={volume}
                        onChange={(e)=>setVolume(e.target.value)}
                        onWheel={e=>e.currentTarget.blur()}
                      />
                    </FormControl>
                    <Button
                      isLoading={isAuthBusy}
                      onClick={()=>sendRequest()}
                      colorScheme={requested?'whatsapp':'brand'}
                      fontSize='sm'
                      fontWeight='500'
                      w='100%'
                      h='50'
                      mb='24px'>
                      {requested?"Submitted":"Submit"}
                    </Button>
                  </Flex>
                </Box>
                <HSeparator mt='10px' mb='10px'/>
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='start'
                  maxW='100%'
                  >
                  <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                    Already Registred?
                    {/* <NavLink to='/auth/sign-up'> */}
                    <Button variant='link' onClick={()=>setRegMode('sign-in')}>
                      <Text
                        color={textColorBrand}
                        textDecoration='underline'
                        as='span'
                        ms='5px'
                        fontWeight='500'>
                        Sign In Here
                      </Text>
                    </Button>
                    {/* </NavLink> */}
                  </Text>
                </Flex>
            </Flex>
            :
          
            <Flex
              maxW={{ base: "100%", md: "500px" }}
              w={{ base: "100%", md: "max-content" }}
              mx={{ base: "auto", lg: "0px" }}
              h='100%'
              alignItems='start'
              justifyContent='start'
              mb={{ base: "30px", md: "40px" }}
              px={{ base: "0px", md: "20px" }}
              // ps={{ base: "initial", md: "70px" }}
              mt={{ base: "30px", md: "40px" }}
              flexDirection='column'
              >
                <Box
                  h='55px'
                >
                  <Image 
                    src={(colorMode==='light')?logo:logowhite} width='100%'
                  />
                  <Text
                    color="#7950f2" fontWeight={600} fontSize='15.2px' marginTop='3px' textAlign='center'
                  >The right fuel for your business growth</Text>
                </Box>
                <Box w='100%' flex={1} display='flex' flexDir='column' justifyContent='center'>
              <Heading color={textColor} fontSize='36px' mb='10px'>
                Sign In
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'>
                {
                  loginMode.includes('mobile')?
                  "Sign in with registered mobile number":
                  "Sign in with registered email and password"
                }
              </Text>
              <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", md: "420px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
              >
                
                {
                  loginMode.includes('mobile')?
                  <>
                    {
                      (loginMode==='mobile-phone'||loginMode==='mobile-submitted')?
                      <FormControl>
                        <FormLabel
                          display='flex'
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          mb='8px'>
                          Mobile Number<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                          bgColor={bgColor}
                          isRequired={true}
                          variant='auth'
                          fontSize='sm'
                          ms={{ base: "0px", md: "0px" }}
                          onWheel={(e)=>e.currentTarget.blur()}
                          disabled={busy}
                          type='number'
                          // maxLength='10'
                          placeholder='Mobile Number'
                          maxLength={10}
                          mb='24px'
                          fontWeight='500'
                          size='lg'
                          value={phone}
                          onChange={(e)=>{if(e.target.value.length<11)setPhone(e.target.value)}}
                          onClick={()=>setShowError(false)}
                        />
                      </FormControl>
                      :
                      <FormControl>
                        <Flex>
                        <FormLabel
                          ms='4px'
                          fontSize='sm'
                          fontWeight='500'
                          color={textColor}
                          display='flex'
                          flex={1}
                          mb='8px'>
                          OTP<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Button variant='link' onClick={()=>window.location.reload()}>
                          <Text
                            color={textColorBrand}
                            textDecoration='underline'
                            as='span'
                            ms='5px'
                            fontWeight='500'>
                            Change Number
                          </Text>
                        </Button>
                        </Flex>
                          <Input
                            bgColor={bgColor}
                            isRequired={true}
                            fontSize='sm'
                            placeholder='6 Digit OTP'
                            disabled={busy}
                            mb='24px'
                            size='lg'
                            type='number'
                            onWheel={(e)=>e.currentTarget.blur()}
                            value={otp}
                            onChange={(e)=>setOtp(e.target.value)}
                          />
                      </FormControl>
                    }
                    {phoneErr && <Alert mb='20px' status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle flex={1}>Incorrect Mobile Number Entered. Please Check the number and try again</AlertTitle>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          onClick={()=>setPhoneErr(false)}
                        />
                    </Alert>}
                    {otpErr && <Alert mb='20px' status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle flex={1}>Incorrect OTP. Please enter correct OTP and try again</AlertTitle>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          onClick={()=>setOtpErr(false)}
                        />
                    </Alert>}
                    {newUserErr && <Alert mb='20px' status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle flex={1}>
                          Phone number not associated with any business account. <Button variant='link' onClick={()=>setRegMode('register')}>
                          <Text
                            color={textColor}
                            textDecoration='underline'
                            as='span'
                            ms='5px'
                            fontWeight='500'>
                            Click here
                          </Text>
                        </Button> to register for new business account
                        </AlertTitle>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          onClick={()=>setNewUserErr(false)}
                        />
                    </Alert>}
                    {
                      (loginMode==='mobile-phone'||loginMode==='mobile-submitted')?
                      <Button
                        isLoading={busy}
                        onClick={()=>sendOTP()}
                        id='phone-signin-btn'
                        fontSize='sm'
                        variant='brand'
                        fontWeight='500'
                        w='100%'
                        h='50'
                        mb='24px'>
                        Send OTP
                      </Button>
                      :
                      <Button
                        isLoading={busy}
                        onClick={()=>submitOTP()}
                        fontSize='sm'
                        variant='brand'
                        fontWeight='500'
                        w='100%'
                        h='50'
                        mb='24px'>
                        Submit
                      </Button>
                    }
                  </>
                  :
                  <>
                    <FormControl>
                      <FormLabel
                        display='flex'
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        mb='8px'>
                        Email<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        bgColor={bgColor}
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        ms={{ base: "0px", md: "0px" }}
                        type='email'
                        placeholder='Email'
                        mb='24px'
                        fontWeight='500'
                        size='lg'
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        onClick={()=>setShowError(false)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        ms='4px'
                        fontSize='sm'
                        fontWeight='500'
                        color={textColor}
                        display='flex'
                        mb='8px'>
                        Password<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <InputGroup size='md'>
                        <Input
                          bgColor={bgColor}
                          isRequired={true}
                          fontSize='sm'
                          placeholder='Password'
                          mb='24px'
                          size='lg'
                          type={showPassword ? "text" : "password"}
                          variant='auth'
                          value={password}
                          onChange={(e)=>setPassword(e.target.value)}
                          onClick={()=>setShowError(false)}
                        />
                        <InputRightElement display='flex' alignItems='center' mt='4px'>
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={()=>setShowPassword(!showPassword)}
                          />
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    {showError && <Alert mb='20px' status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle flex={1}>{errorText}</AlertTitle>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          onClick={()=>setShowError(false)}
                        />
                    </Alert>}
                    {newUserErr && <Alert mb='20px' status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle flex={1}>
                          Login details not associated with any business account. Please contact us to register for new business account
                        </AlertTitle>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          onClick={()=>setNewUserErr(false)}
                        />
                    </Alert>}
                    <Button
                      isLoading={isAuthBusy}
                      onClick={()=>login()}
                      fontSize='sm'
                      variant='brand'
                      fontWeight='500'
                      w='100%'
                      h='50'
                      mb='24px'>
                      Sign In
                    </Button>
                  </>
                }
                <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  maxW='100%'
                  mt='-10px'>
                    {
                      loginMode.includes('mobile')?
                      <Button variant='link' onClick={()=>setLoginMode('email')}>
                        <Text
                          color={textColorBrand}
                          textDecoration='underline'
                          as='span'
                          ms='5px'
                          fontWeight='500'>
                          Login With Email
                        </Text>
                      </Button>
                      :
                      <Button variant='link' onClick={()=>setLoginMode('mobile-phone')}>
                        <Text
                          color={textColorBrand}
                          textDecoration='underline'
                          as='span'
                          ms='5px'
                          fontWeight='500'>
                          Login With Phone
                        </Text>
                      </Button>
                    }
                </Flex>
              </Flex>
            </Box>
            <HSeparator mt='10px' mb='10px'/>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
            >
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not registered yet?
                {/* <NavLink to='/auth/sign-up'> */}
                <Button variant='link' onClick={()=>setRegMode('register')}>
                  <Text
                    color={textColorBrand}
                    textDecoration='underline'
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Register Here
                  </Text>
                </Button>
                {/* </NavLink> */}
              </Text>
            </Flex>
          </Flex>
          }
          {true && <Box
            display={{ base: 'none', md: 'block' }}
            background='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
            h='100%'
            borderTopRightRadius='20px'
            borderBottomRightRadius='20px'
            maxW='calc( 100% - 500px)'
            w={{ lg: '70vw', '2xl': '70vw' }}
            flex={1}
            position='absolute'
            right={0}
          >
            <Flex
              // bgColor={(colorMode==='light')?'white':'#2e3d76'}
              flexDir='column'
              justify='center'
              align='center'
              px='30px'
              w='100%'
              h='100%'
              bgSize='cover'
              bgPosition='50%'
              position='absolute'
              // borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
              overflowY='scroll'
            >
              {/* <Image src={(colorMode==='light')?logo:logowhite} maxW='70%' mt='20px' mb='10px'/>
              <Lottie
                animationData={animation_conveyor}
                loop={true}
                style={{height:'400px',margin:'10px 0px'}}
                // initialSegment={[0,77]}
              />
              <Text fontWeight={600} textTransform='capitalize' textAlign='center' color={textColorBrand} lineHeight={1.1} my='20px' fontSize='30px'>
                Unleash Endless Delivery Possibilities<br/>Powered by One Platform!
              </Text> */}
              <Swiper
                  // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                  speed={1000}
                  className='dark-swiper'
                  // allowTouchMove={false}
                  pagination={{clickable: true}}
                  autoplay={{ delay: 6000 }}
                  spaceBetween={0}
                  slidesPerView={1}
                  loop={true}
                  grabCursor={true}
                  onSwiper={setSwiper}
                  onSlideChange={(swiper) => {
                      setActiveSlide(swiper.activeIndex - 1)
                  }}
                  style={{
                      padding: '0px', width: '100%', height:'calc( 100% - 10px )'
                  }}
              > 
                {slidesArr.map((slideEl,i)=>{
                  const { animation, title, subTitle } = slideEl
                  return(
                    <SwiperSlide key={'landing_slide_'+i} style={{height:'calc( 100% - 50px )', padding:'20px', borderRadius:'20px', overflow:'hidden'}}>
                      <Flex direction='column' justifyContent='center' alignItems='center' p='20px' h='100%'>
                        <Text fontWeight={600} textTransform='uppercase' textAlign='center' color={'white'} lineHeight={1.1} fontSize='30px'>
                          {title}
                        </Text>
                        <Lottie
                          animationData={animation}
                          loop={true}
                          style={{ margin:'10px 0px', width:'100%',  maxHeight:'250px'}}
                          // initialSegment={[0,77]}
                        />
                        <Text fontWeight={600} textAlign='center' color={'white'} lineHeight={1.1} mt='5px' fontSize='20px'>
                          {subTitle}
                        </Text>
                      </Flex>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              {/* <Image 
                px={{ lg: '30px', xl: '25px' }} src={landingImage} h='500px' mt='20px'
              /> */}
            </Flex>
          </Box>}
			</Flex>
			<FixedPlugin />
      <Footer />
		</Flex>
    </>
	);
}
