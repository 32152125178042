import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = 'http://localhost:8000'
let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";

export interface SearchPayload{
    weight:number,
    vehicle: number,
    start:{
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city: string,
        country: string
    },
    end: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city: string,
        country: string
    },
    sku: {
        sku_id: string,
        sku_name: string,
        qty: number,
        packageIndex: number
    }[]
}

export interface OrderPayload{
    shipperID: string,
    externalOrderID?: string,
    channel?: string,
    vehicle: number,
    start:{
        contact:{
            name:string,
            phone:string
        },
        address:{
            houseNo:string,
            buildingName:string,
            addressType: string,
            mapData:{
                lat: number,
                lng: number,
                address: string,
                addressType: string,
                pincode: string,
                state: string,
                city: string,
                country: string
            }
        }
    },
    end:{
        contact:{
            name:string,
            phone:string
        },
        address:{
            houseNo:string,
            buildingName:string,
            addressType: string,
            mapData:{
                lat: number,
                lng: number,
                address: string,
                addressType: string,
                pincode: string,
                state: string,
                city: string,
                country: string
            }
        }
    },
    sku: {
        sku_id: string,
        sku_name: string,
        qty: number,
        packageIndex: number
    }[ ],
    package:{
        l?:number,
        b?:number,
        h?:number,
        weight:number
    }[ ]
}

export async function searchHyperlocalOnDemandShippersAPI(payload: SearchPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/hyperlocal/search`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function placeHyperlocalOnDemandOrderAPI(payload:OrderPayload) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/hyperlocal/on-demand/createOrder`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err })
            });
    });
}

export async function getAllHyperlocalOrdersAPI(query:{page?: number, limit?: number}) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/hyperlocal/getAllOrders`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params:{
                page: query.page,
                limit: query.limit
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getSingleHyperlocalOrderAPI(id: string | undefined) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/hyperlocal/getSingleOrder/${id}`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}