import { Button, Input, Menu, MenuButton, MenuItem, MenuList, useColorModeValue } from '@chakra-ui/react';
import { FaChevronDown, FaTimesCircle } from 'react-icons/fa';

interface ChakraSelectProps {
    options: any[],
    onSelect: Function,
    value?: string,
    placeHolder?: string,
    fontWeight?: number,
    canClear?: boolean,
    isInvalid?: boolean,
    disabled?: boolean,
    size?: string,
    style?: any,
    ref?: any,
    id?: string
}

export default function ChakraSelect({ options, onSelect, value, placeHolder, canClear, isInvalid, fontWeight, disabled, size, style, id, ref }: ChakraSelectProps): JSX.Element {
	const bgColor = useColorModeValue('white', '#111c44');

    return (
		<Menu>
            <MenuButton disabled={disabled} bgColor={bgColor} fontWeight={fontWeight?fontWeight:'normal'} textAlign='start' borderRadius='2px'  style={style} minW='150px' w='100%' as={Button}
            variant='outline' rightIcon={<FaChevronDown />} borderColor={isInvalid?'#EE5D50':undefined} borderWidth={isInvalid?'3px':undefined} size={size}>
                <Input ref={ref} id={id} variant='unstyled' value={value?options.find((element) => element.value === value)?.label:(placeHolder?placeHolder:'Select an option')} size={size}/>
            </MenuButton>
            <MenuList maxH='400px' overflowY='scroll'>
                {canClear && <MenuItem disabled={value?true:false} color='crimson' fontSize='15px' onClick={()=>onSelect('')}>Clear&nbsp;<FaTimesCircle/></MenuItem>}
                {options.map((el,i)=>{
                    return <MenuItem fontSize='15px' key={'select_option_'+i} onClick={()=>onSelect(el.value)}>{el.label}</MenuItem>
                })}
            </MenuList>
        </Menu>
    )
}