import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserDataAPI, editUserDataAPI } from 'app/api/auth';
import { signout } from 'app/api/firebase';
import { RootState, AppThunk } from '../store';
export interface AuthState {
    fetching: boolean,
    ready: boolean,
    isAuth: boolean;
    user: any;
}

const initialState: AuthState = {
    fetching: false,
    isAuth: false,
    ready: false,
    user: undefined
}

export const getUserData = createAsyncThunk(
    'auth/getUserData',
    async () => {
        const response = await getUserDataAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const editUserData = createAsyncThunk(
    'auth/editUserData',
    async (payload:any) => {
        const response = await editUserDataAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const signoutAction = createAsyncThunk(
    'user/signout',
    async () => {
      const response = await signout();
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthBusy: (state,action) => {
            state.fetching = action.payload;
        },
        updateUser: (state,action) => {
            state.user = action.payload;
        },
        logOut: (state) => {
            signout()
            state.user = undefined;
            state.isAuth = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserData.pending, (state) => {
                state.fetching = true
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.fetching = false
                state.isAuth = true;
                const { data } = action.payload;
                state.user = data;
            })
            .addCase(getUserData.rejected, (state) => {
                state.fetching = false
            })

            .addCase(editUserData.pending, (state) => {
                state.fetching = true
            })
            .addCase(editUserData.fulfilled, (state, action) => {
                state.fetching = false
                state.isAuth = true;
                const { data } = action.payload;
                state.user = data;
            })
            .addCase(editUserData.rejected, (state) => {
                state.fetching = false
            })

            .addCase(signoutAction.pending, (state) => {
                state.fetching = true
            })
            .addCase(signoutAction.fulfilled, (state) => {
                state.isAuth = false;
                state.fetching = false;
                state.user = undefined;
            })
            .addCase(signoutAction.rejected, (state, action) => {
                console.log("RES", action);
                state.isAuth = false
                state.fetching = false
                state.user = undefined;
            })
    }
})

export const { logOut, setAuthBusy, updateUser } = authSlice.actions;

export const getAuth = (state: RootState) => state.auth.isAuth;
export const getAuthBusy = (state: RootState) => state.auth.fetching;
export const getUser = (state: RootState) => state.auth.user;
export const getReady = (state: RootState) => state.auth.ready;

export default authSlice.reducer;