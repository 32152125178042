import { useState } from "react";
import 'swiper/scss';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, Alert, AlertIcon, Box, Button, ModalFooter, Flex, useColorModeValue, useToast, FormControl, FormLabel, Badge } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cancelPanIndiaOrder, getPanIndiaOrderFetchingState } from "app/reducer/panindia.reducer";
import { HSeparator } from "components/separator/Separator";
import { GiCancel } from "react-icons/gi";
import { unwrapResult } from "@reduxjs/toolkit";

function PackageItem(props) {

	const {packageData, index, onBook} = props;
	const {awb, status} = packageData;

    const fetching = useAppSelector(getPanIndiaOrderFetchingState);

	const [busy, setBusy] = useState<boolean>(false);

	let badgeColor = 'yellow'
	let canCancel = false
	let cancelText = 'Package already in transit. Cannot be cancelled'

	switch (status) {
		case undefined:
			badgeColor = 'green'
			canCancel = true
			cancelText = 'Cancellation available if done before pickup.'
			break;
		case 'Manifested':
			badgeColor = 'green'
			canCancel = true
			cancelText = 'Cancellation available if done before pickup.'
			break;
	
		default:
			break;
	}

	if(!awb){
		badgeColor = 'green'
		canCancel = true
		cancelText = 'AWB not manifested. Cancellation available.'
	}


	return (
		<Box w='32%'>	
			<Card border='2px solid #E9ECF2' width='initial' flexDirection='column' alignItems='start' mr='20px'>
				<Box display='flex' w='100%'>
					<Flex px='5px' justify='space-between' mb='10px' align='center'>
						<Text flex={2} fontSize='18px' fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
							Package&nbsp;{index+1}
						</Text>
						<Badge ml='20px' variant='solid' colorScheme={status?badgeColor:'gray'}>
							{status?status:(awb?'Manifested':'Not Manifested')}
						</Badge>
					</Flex>
				</Box>
				<HSeparator/>
				<FormControl fontSize='14px' fontWeight={600} mt='10px'>
					<FormLabel fontSize='14px' fontWeight={'normal'} m='0px'>
						AWB:&nbsp;
					</FormLabel>
					{awb?awb:'N/A'}
				</FormControl>
				<FormControl fontSize='14px' fontWeight={600} mt='10px'>
					<FormLabel fontSize='14px' fontWeight={'normal'} m='0px'>
						Cancellation:&nbsp;
					</FormLabel>
					<Alert status={canCancel?'success':'warning'} fontSize='12px' borderRadius='10px' mb='10px'>
						<AlertIcon />
						<Box>
                            {cancelText}
						</Box>
					</Alert>
				</FormControl>
			</Card>
		</Box>
	)
}

export default function CancelModal(props): JSX.Element {

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	const startTextColor = useColorModeValue('green.600', 'green.100');
	const endTextColor = useColorModeValue('brand.500', 'blue.100');
	const errorTextColor = useColorModeValue('red.600', 'red.100');

	const toast = useToast()
    const dispatch = useAppDispatch()

    const {isOpen, onClose, orderData, callback } = props;

    const [shipperResults, setShipperResults] = useState<any[]>([])

    const [busyCancel, setBusyCancel] = useState<boolean>(false);
    
    const cancelOrder = () => {

        const startObj = orderData.start.address.mapData

        const endObj = orderData.end.address.mapData

        let awbArr:string[] = [];
        orderData.package.forEach((el,i)=>{
			if(el.status==='Manifested'){
				awbArr.push(el.awb)
			}
        })

        const cancelParams = {
            orderID: orderData.sm_orderid,
            awbs: awbArr
        }

        // console.log(data);
        setBusyCancel(true);
        dispatch(cancelPanIndiaOrder(cancelParams)).then(unwrapResult)
        .then((res:any) => {
            console.log(res);
			switch (res.packagesCancelled) {
				case 'none':
					toast({
						title: 'Something went wrong when cancelling your order.',
						description: "This usually happens when shipper servers are busy. Please try again or contact support for help.",
						status: 'error',
						position: 'bottom-right',
						isClosable: true,
					})
					// onClose();
					break;
				case 'some':
					toast({
						title: 'Order '+orderData.sm_orderid+' partially cancelled',
						description: `Order successfully cancelled.`+ (orderData.package.length>awbArr.length?' Some packages were already in transit, they will not be cancelled.':''),
						status: 'success',
						variant: 'subtle',
						position: 'bottom-right',
						isClosable: true,
					})
					if(callback){
						callback();
					}
					onClose();
					break;
				case 'all':
					toast({
						title: 'Order '+orderData.sm_orderid+' cancelled',
						description: `Order successfully cancelled.`+ (orderData.package.length>awbArr.length?' If some packages were already in transit they will not be cancelled.':''),
						status: 'success',
						variant: 'subtle',
						position: 'bottom-right',
						isClosable: true,
					})
					if(callback){
						callback();
					}
					onClose();
					break;
			
				default:
					break;
			}

        }).catch((e) => {
            console.log(e);
			toast({
				title: 'Something went wrong when cancelling your order.',
				description: e.data.message as string+". Please try again or contact support for help.",
				status: 'error',
				position: 'bottom-right',
				isClosable: true,
			})
        }).finally(() => {
            setBusyCancel(false);
        })
    }

    return (
        <Modal 
            closeOnOverlayClick={true}
            isCentered motionPreset='slideInBottom' size='full'
            isOpen={isOpen} onClose={()=>onClose()}
        >
            <ModalOverlay
            backdropFilter='blur(10px)'
            />
            <ModalContent>
                <ModalHeader display='flex'>
                    <Text flex={1} fontSize='22px' fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
					    Cancel Order
				    </Text>
                </ModalHeader>
                <ModalBody mx='40px' my='20px' bg='#dff2ff' p='20px' borderRadius='20px'>
                    <Alert status='warning' fontSize='12px' borderRadius='10px' mb='10px' style={{zoom:1.2}}>
						<AlertIcon />
						<Box>
                            Only AWBs that are not picked up can be cancelled. If some packages are eligible for cancellation, only those will be cancelled.<br/>
							<b>If you need help with orders that are already in transit, contact support.</b>
						</Box>
					</Alert>
					<Card display='flex' flexDirection='column'>
						<Flex color={endTextColor} fontSize='22px' px='5px' justify='space-between' mb='10px' align='center'>
							<Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
								Order package details:
							</Text>
						</Flex>
						<Box flex={1} display='flex'> 
							{orderData.package.map((packageEl,i)=>{
								return <PackageItem key={'package_'+i} index={i} packageData={packageEl}/>
							})}
						</Box>
					</Card>
					<Card display='flex' flexDirection='column' mt='10px'>
						<Flex px='5px' justify='center' align='center'>
							<Button leftIcon={<GiCancel/>} colorScheme='red' isLoading={busyCancel} onClick={()=>cancelOrder()}>
								Cancel This Order
							</Button>
						</Flex>
					</Card>

                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' onClick={()=>onClose()} mr='10px'>
                        Back
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    );

}
