import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";

export async function checkNumberRegisteredAPI(number:string) {

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/checkUserExists`,
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            data: {phone:number}
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getUserDataAPI() {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/getUserDetail`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editUserDataAPI(payload:any) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/updateUser`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function generateAPIKey() {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/generateAPIKey`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createWebhook(payload:{type:string,webhookURL:string,webhookSecret:string}) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/createWebhook`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}