import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = 'http://localhost:8000'
let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";

export interface AddressPayload {
    external_id?: string,
    name: string,
    houseNo: string,
    buildingName: string,
    addressType: string,
    mapData: {
        lat: number,
        lng: number,
        address: string,
        pincode: string,
        state: string,
        city: string,
        country: string
    }
}

export interface SKUPayload {
    sku_name: string,
    sku_id: string,
    sku_value: string
}

export async function getAllStoreAddressesAPI(query:{page?: number, limit?: number, searchString?: string}) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/getBusinessStores`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params:query
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllCustomerAddressesAPI(query:{page?: number, limit?: number, searchString?: string}) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/getCustomerAddress`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params:query
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllSupplierAddressesAPI(query:{page?: number, limit?: number, searchString?: string}) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/getSupplierAddress`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params:query
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllSKUAPI(query:{page?: number, limit?: number, searchString?: string}) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/getBusinessSKU`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params:query
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createStoreAddressAPI(payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/createBusinessStores`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createCustomerAddressAPI(payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/createCustomerAddress`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createSupplierAddressAPI(payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/createSupplierAddress`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function createSKUAPI(payload: SKUPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/createBusinessSKU`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editStoreAddressAPI(id: string, payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/updateBusinessStores/${id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editCustomerAddressAPI(id: string, payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/updateCustomerAddress/${id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editSupplierAddressAPI(id: string, payload: AddressPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/updateSupplierAddress/${id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function editSKUAPI(id: string, payload: SKUPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/updateBusinessSKU/${id}`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function printLabelAPI(orderid: string) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/label`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            params: {
                order_id: orderid
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function printLabelBulkAPI(orderIds: string[]) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/bulk-label`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: {
                orderIds: orderIds
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllCities(){
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/cities`,
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}
export function sendBusinessEnquiryAPI(formData: any) {
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/create-business-enquiry`,
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: formData
        })
            .then((res) => {
                resolve({ response: res })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });
    });
}