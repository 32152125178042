import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RTOPayload, createRTOAPI } from 'app/api/general';
import { getAllPanIndiaOrdersAPI, getSinglePanIndiaOrderAPI, OrderPayload, searchPanIndiaShippersAPI, placePanIndiaOrderAPI, SearchPayload, allocatePanIndiaShipperAPI, cancelPanIndiaOrderAPI, saveDraftPanIndiaOrderAPI, getAllPanIndiaDraftOrdersAPI, deletePanIndiaOrderAPI, getAllOrdersWithoutPagination } from 'app/api/panindia';
import { RootState } from '../store';

export interface GeneralState {
    orders: any[],
    draftOrders: any[],
    ordersWithoutPagingation: any[],
    page: number,
    limit: number,
    totalPages: number,
    placingOrders: boolean,
    fetchingOrders: boolean,
    orderDetails: any,
    repeatOrderData:any,
    shipperFilter: string[],
    orderMode: "surface-delivery" | "express-delivery"
}

const initialState: GeneralState = {
    orders: [],
    draftOrders: [],
    ordersWithoutPagingation: [],
    page: 1,
    limit: 20,
    totalPages: 1,
    placingOrders: false,
    fetchingOrders: false,
    orderDetails: {},
    repeatOrderData: undefined,
    shipperFilter: [],
    orderMode: "surface-delivery"
}

export const getAllPanIndiaOrders = createAsyncThunk(
    'panindia/getAllPanIndiaOrders',
    async (query: {
        page?: number,
        limit?: number,
        order_status?: string,
        searchString?: string,
        shippers?: string[],
        senderName?: string,
        startDate?: Date,
        endDate?: Date
    }) => {
        const response = await getAllPanIndiaOrdersAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllPanIndiaOrdersWithoutPagination = createAsyncThunk(
    'panindia/getAllPanIndiaOrdersWithoutPagination',
    async(query: {
        page?: number,
        limit?: number,
        order_status?: string,
        searchString?: string,
        shippers?: string[],
        senderName?: string,
        startDate?: Date,
        endDate?: Date
    }) => {
        const response  = await getAllOrdersWithoutPagination(query);
        return response.response;
    }
)

export const getAllPanIndiaDraftOrders = createAsyncThunk(
    'panindia/getAllPanIndiaDraftOrders',
    async (query: {
        page?: number,
        limit?: number,
        searchString?: string,
        senderName?: string,
        startDate?: Date,
        endDate?: Date
    }) => {
        const response = await getAllPanIndiaDraftOrdersAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const getSinglePanIndiaOrder = createAsyncThunk(
    'panindia/getSinglePanIndiaOrder',
    async ({ orderID }: { orderID: string | undefined }) => {
        const response = await getSinglePanIndiaOrderAPI(orderID);
        return response.response;
    }
);

export const placePanIndiaOrder = createAsyncThunk(
    'panindia/placePanIndiaOrder',
    async (data: OrderPayload, { rejectWithValue }) => {
        // The value we return becomes the `fulfilled` action payload
        try {
            const response = await placePanIndiaOrderAPI(data);
            return response.response;
        } catch (err: any) {
            return rejectWithValue(err.error.response)
        }
    }
);

export const saveDraftPanIndiaOrder = createAsyncThunk(
    'panindia/saveDraftPanIndiaOrder',
    async (data: Partial<OrderPayload>, { rejectWithValue }) => {
        // The value we return becomes the `fulfilled` action payload
        try {
            // const response = await placePanIndiaOrderAPI(data);
            const response = await saveDraftPanIndiaOrderAPI(data);
            return response.response;
        } catch (err: any) {
            return rejectWithValue(err.error.response)
        }
    }
);

export const cancelPanIndiaOrder = createAsyncThunk(
    'panindia/cancelPanIndiaOrder',
    async (data: { orderID: string, awbs: string[] }, { rejectWithValue }) => {
        // The value we return becomes the `fulfilled` action payload
        try {
            const response = await cancelPanIndiaOrderAPI(data);
            return response.response;
        } catch (err: any) {
            return rejectWithValue(err.error.response)
        }
    }
);

export const deletePanIndiaOrder = createAsyncThunk(
    'panindia/deletePanIndiaOrder',
    async (orderID: string , { rejectWithValue }) => {
        // The value we return becomes the `fulfilled` action payload
        try {
            const response = await deletePanIndiaOrderAPI(orderID);
            return {"response": response.response, "orderId": orderID};
        } catch (err: any) {
            return rejectWithValue(err.error.response)
        }
    }
)

export const searchPanIndiaShippers = createAsyncThunk(
    'panindia/searchPanIndiaShippers',
    async (data: SearchPayload) => {
        const response = await searchPanIndiaShippersAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const allocateShipper = createAsyncThunk(
    'panindia/allocateShipper',
    async (payload: { sm_orderid: string, shipperID: string }) => {
        const response = await allocatePanIndiaShipperAPI(payload);
        return response.response;
    }
);


export const panindiaSlice = createSlice({
    name: 'panindia',
    initialState,
    reducers: {
        setOrderMode(state,action){
            state.orderMode = action.payload
        },
        setPanIndiaRepeatOrder(state, action) {
            state.repeatOrderData = action.payload
        },
        setPanIndiaShipperFilter(state, action) {
            console.log("Payload", action.payload)
            state.shipperFilter = action.payload
        },
        clearOrderDetail(state) {
            state.orderDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPanIndiaOrders.pending, (state) => {
                state.fetchingOrders = true
                state.orders = [];
                state.totalPages = 1
            })
            .addCase(getAllPanIndiaOrders.fulfilled, (state, action) => {
                const { results, next, totalPages } = action.payload.data;
                // let orders = state.orders
                // results.forEach(element => {
                //     orders.push(element);
                // });
                state.orders = results;
                // if(next){
                //     state.page = next.page;
                // }else{
                //     state.page = 0;
                // }
                state.totalPages = totalPages ? totalPages : 1;
                state.fetchingOrders = false
            })
            .addCase(getAllPanIndiaOrders.rejected, (state) => {
                state.fetchingOrders = false
                state.orders = [];
                state.totalPages = 1
            })

            .addCase(getAllPanIndiaOrdersWithoutPagination.pending, (state, action) => {
                state.fetchingOrders = true
                state.ordersWithoutPagingation = [];
                state.orders = [];
                state.totalPages = 1
            })
            .addCase(getAllPanIndiaOrdersWithoutPagination.fulfilled, (state, action) => {
                // const { results } = action.payload.data;
                console.log(action.payload.data)
                // console.log("Results", results)
                state.ordersWithoutPagingation = action.payload.data;
                state.orders = action.payload.data;
                state.fetchingOrders = false;
            })
            .addCase(getAllPanIndiaOrdersWithoutPagination.rejected, (state, action) => {
                state.fetchingOrders = false
                state.ordersWithoutPagingation = [];
                state.orders = [];
            })

            .addCase(getAllPanIndiaDraftOrders.pending, (state) => {
                state.fetchingOrders = true
                state.draftOrders = [];
                state.totalPages = 1
            })
            .addCase(getAllPanIndiaDraftOrders.fulfilled, (state, action) => {
                const { results, next, totalPages } = action.payload.data;
                // let orders = state.orders
                // results.forEach(element => {
                //     orders.push(element);
                // });
                state.draftOrders = results;
                // if(next){
                //     state.page = next.page;
                // }else{
                //     state.page = 0;
                // }
                state.totalPages = totalPages ? totalPages : 1;
                state.fetchingOrders = false
            })
            .addCase(getAllPanIndiaDraftOrders.rejected, (state) => {
                state.fetchingOrders = false
                state.draftOrders = [];
                state.totalPages = 1
            })

            .addCase(getSinglePanIndiaOrder.pending, (state) => {
                state.orderDetails = {};
                state.fetchingOrders = true
            })
            .addCase(getSinglePanIndiaOrder.fulfilled, (state, action) => {
                state.orderDetails = action.payload.data;
                state.fetchingOrders = false
            })
            .addCase(getSinglePanIndiaOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(placePanIndiaOrder.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(placePanIndiaOrder.fulfilled, (state, action) => {
                let orders = state.orders
                orders.push(action.payload.data);
                state.orders = orders;
                state.fetchingOrders = false
            })
            .addCase(placePanIndiaOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(saveDraftPanIndiaOrder.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(saveDraftPanIndiaOrder.fulfilled, (state, action) => {
                let draftOrders = state.draftOrders
                draftOrders.push(action.payload.data);
                state.draftOrders = draftOrders;
                state.fetchingOrders = false
            })
            .addCase(saveDraftPanIndiaOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(cancelPanIndiaOrder.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(cancelPanIndiaOrder.fulfilled, (state, action) => {
                let orders = state.orders
                const newOrder = action.payload.data;
                for (let i = 0; i < orders.length; i++) {
                    const currentOrder = orders[i];
                    if (currentOrder.sm_orderid === newOrder.sm_orderid) {
                        orders[i] = newOrder;
                        break
                    }
                }
                state.orders = orders;
                state.fetchingOrders = false
            })
            .addCase(cancelPanIndiaOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(deletePanIndiaOrder.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(deletePanIndiaOrder.fulfilled, (state, action) => {
                let orders = state.orders
                const {orderId} = action.payload;
                state.orders = orders.filter((order) => order.sm_orderid != orderId);
                state.fetchingOrders = false
            })
            .addCase(deletePanIndiaOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(allocateShipper.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(allocateShipper.fulfilled, (state, action) => {
                let orders = state.orders
                const newOrder = action.payload.data;
                for (let i = 0; i < orders.length; i++) {
                    const currentOrder = orders[i];
                    if (currentOrder.sm_orderid === newOrder.sm_orderid) {
                        orders[i] = newOrder;
                        break
                    }
                }
                state.orders = orders;
                state.fetchingOrders = false
            })
            .addCase(allocateShipper.rejected, (state) => {
                state.fetchingOrders = false
            })

    }
})

export const { setPanIndiaRepeatOrder, clearOrderDetail, setOrderMode, setPanIndiaShipperFilter } = panindiaSlice.actions

export const getPanIndiaPageState = (state: RootState) => state.panindia.page
export const getPanIndiaOrdersState = (state: RootState) => state.panindia.orders
export const getAllPanIndiaOrdersWithoutPaginationState = (state: RootState) => state.panindia.ordersWithoutPagingation
export const getPanIndiaDraftOrdersState = (state: RootState) => state.panindia.draftOrders
export const getPanIndiaOrdersTotalPages = (state: RootState) => state.panindia.totalPages
export const getPanIndiaOrderDetailState = (state: RootState) => state.panindia.orderDetails
export const getPanIndiaOrderFetchingState = (state: RootState) => state.panindia.fetchingOrders
export const getPanIndiaOrderPlacingState = (state: RootState) => state.panindia.placingOrders
export const getPanIndiaRepeatOrderState = (state: RootState) => state.panindia.repeatOrderData
export const getPanIndiaOrderModeState = (state: RootState) => state.panindia.orderMode
export const getShipperFilterState = (state: RootState) => state.panindia.shipperFilter


export default panindiaSlice.reducer;