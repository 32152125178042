/* eslint-disable */
import { Badge, Box, Button, Grid, CircularProgress, Flex, GridItem, Text, useColorModeValue, useDisclosure, Modal, ModalContent, ModalOverlay, ModalBody, ModalFooter, ModalHeader } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import 'swiper/scss';
// Custom components
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import { useState, useEffect} from 'react';
import { FaCheckCircle, FaMapMarkerAlt, FaSearch, FaStore } from 'react-icons/fa';
import { FiExternalLink} from 'react-icons/fi';
import { getPanIndiaOrderDetailState, getPanIndiaOrderFetchingState, getSinglePanIndiaOrder, setPanIndiaRepeatOrder } from 'app/reducer/panindia.reducer';
import { printLabelAPI } from 'app/api/general';
import { useNavigate, useParams } from 'react-router-dom';
import { HiClock, HiDocumentText } from 'react-icons/hi2';
import { GiCancel } from 'react-icons/gi';
import { BiSelectMultiple } from 'react-icons/bi';
import CancelModal from 'components/modals/CancelModal';
import { BsClipboard2CheckFill } from 'react-icons/bs';

function PackageItem(props) {

	const {packageData, index, onBook, shipperTrackingBaseURI} = props;
	const {awb, l, b, h, weight, sku, status, event} = packageData;
	const brandColor = useColorModeValue('brand.500', 'white');

    const fetching = useAppSelector(getPanIndiaOrderFetchingState);
	const { isOpen, onOpen, onClose } = useDisclosure()

	const [busy, setBusy] = useState<boolean>(false);

	let badgeColor = 'green'

	switch (status) {
		case 'Placed':
			badgeColor = 'blue'
			break;
	
		default:
			break;
	}

	if(!awb){
		badgeColor = 'yellow'
	}


	return (
		<Box w='300px'>	
			<Card border='2px solid #E9ECF2' width='initial' flexDirection='column' alignItems='start' mr='20px'>
				<Box display='flex' w='100%'>
					<Flex px='5px' justify='space-between' mb='10px' align='center'>
						<Text flex={2} fontSize='18px' fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
							#&nbsp;{index+1}
						</Text>
						<Badge ml='20px' variant='subtle' colorScheme={status?badgeColor:'gray'}>
                        {status?status:(awb?'Manifested':'Not Manifested')}
						</Badge>
					</Flex>
				</Box>
				<HSeparator my='10px'/>
                
                <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>AWB:</b>
                    </div>
                    <div>
                        {awb?awb:'N/A'}
                    </div>
                </Box>

                <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>Weight:</b>
                    </div>
                    <div>
                        {weight} KG
                    </div>
                </Box>

                <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>Dimensions:</b>
                    </div>
                    <div>
                        {l}x{b}x{h} CM<sup>3</sup>
                    </div>
                </Box>

				<HSeparator my='10px'/>

                {sku[0].sku_name && <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>Item:</b>
                    </div>
                    <div>
                        {sku[0].sku_name}
                    </div>
                </Box>}

                {sku[0].sku_qty && <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>Qty:</b>
                    </div>
                    <div>
                        x{sku[0].sku_qty}
                    </div>
                </Box>}

                {sku[0].sku_value && <Box display='flex'>
                    <div style={{minWidth:'100px'}}>
                        <b>Item Value:</b>
                    </div>
                    <div>
                        {sku[0].sku_value}
                    </div>
                </Box>}

				<HSeparator my='10px'/>

                {event.length>0 && <Button size='sm' isFullWidth colorScheme='green' leftIcon={<FaSearch/>}
                    onClick={()=>onOpen()}
                >
                    Track
                </Button>}
                {shipperTrackingBaseURI && <Button size='sm' marginTop={3} isFullWidth colorScheme='brand' leftIcon={<FiExternalLink />}
                    onClick={()=>window.open(shipperTrackingBaseURI+awb)}
                >
                    Shipper Tracking
                </Button>}
			</Card>


            {isOpen && <Modal 
                closeOnOverlayClick={true}
                isCentered motionPreset='slideInBottom' size='xl'
                scrollBehavior='inside'
                isOpen={isOpen} onClose={()=>onClose()}
            >
                <ModalOverlay
                backdropFilter='blur(10px)'
                />
                <ModalContent>
                <ModalHeader display='flex'>
                    <Text flex={1} fontSize='22px' color={brandColor} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
					    Package #{index+1} Tracking
				    </Text>
                </ModalHeader>
                <ModalBody mx='40px' my='20px' p='20px' bg='#dff2ff' borderRadius='20px'>
                <Box display='flex' flexDirection='column-reverse'>
                    {event.map((eventEl, jindex) => {

                        const dateObj = new Date(eventEl.timeStamp)
                        const timeString = dateObj.toTimeString().split(' ')[0]
                        return (
                            <Box display='flex' key={index+'_track_'+jindex}>
                                <Box color={brandColor} w='30px' zIndex={2} pt='7px'>
                                    <FaCheckCircle fontSize='24px' style={{marginTop:'10px'}}/>
                                    <Box borderLeft={jindex!==0?'3px solid':undefined} borderColor={brandColor} h='calc( 100% - 23px )' mt='3px' ml='10px'></Box>
                                </Box>
                                <Card mt='-10px' ml='-38px' pl='40px' zIndex={1} mb='25px'>
                                    <Text fontWeight='700' textTransform='capitalize'>{eventEl.status}</Text>
                                    {eventEl.location &&
                                    <Text display='flex' alignItems='center' fontSize='12px'>
                                        <FaMapMarkerAlt/>&nbsp;{eventEl.location}
                                    </Text>
                                    }
                                    <Text display='flex' alignItems='center' mt='-3px' fontSize='12px'>
                                        <HiClock/>&nbsp;
                                        {dateObj.toLocaleString().split(',')[0]}
                                        &nbsp;{timeString.split(':')[0]+":"+timeString.split(':')[1]}
                                    </Text>
                                </Card>
                            </Box>
                        )
                    })}
                </Box>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' onClick={()=>onClose()} mr='10px' disabled={busy}>
                        Close
                    </Button>
                </ModalFooter>

                </ModalContent>
            </Modal>}
		</Box>
	)
}

export default function PanIndiaOrderDetails() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	const startTextColor = useColorModeValue('green.600', 'green.100');
	const endTextColor = useColorModeValue('brand.500', 'blue.100');
	const errorTextColor = useColorModeValue('red.600', 'red.100');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { orderID } = params;

    const orderDetails = useAppSelector(getPanIndiaOrderDetailState);
    const fetching = useAppSelector(getPanIndiaOrderFetchingState);

	const { isOpen: isOpenCancelModal, onOpen: onOpenCancelModal, onClose: onCloseCancelModal } = useDisclosure()

    let h1 = "ORDER PLACED"
    let h2 = "Waiting for rider to be assigned"
    let sub1 = "Please wait. We are searching for a suitable rider for you..."
    let sub2 = "This might take a few minutes."

    // let imgSrc = ORDER_DETAIL_TIME;
    let activeStep = 1;
    let orderType = 'Pan-India Surface';

    switch (orderDetails.order_type) {
      case 'pi_express':
        orderType = "Pan-India Surface"
        break;
    
      default:
        break;
    }

    if (!fetching) {

        switch (orderDetails.order_status) {
            case 'Initiated':
                h1 = "ORDER INITIATED"
                h2 = "Waiting for rider to be assigned"
                sub1 = "Please wait. We are searching for a suitable rider for you..."
                sub2 = "This might take a few minutes."
                // imgSrc = ORDER_DETAIL_TIME;
                activeStep = 1;
                break;
            case 'Placed':
                h1 = "ORDER PLACED"
                h2 = "Waiting for rider to be assigned"
                sub1 = "Please wait. We are searching for a suitable rider for you..."
                sub2 = "This might take a few minutes."
                // imgSrc = ORDER_DETAIL_TIME;
                activeStep = 2;
                break;
            case 'In Progress':
                h1 = "RIDER ASSIGNED"
                h2 = "Rider is heading to pick up location"
                sub1 = "Rider has been assigned and will be at the pickup location shortly."
                sub2 = "This might take a few minutes."
                // imgSrc = ORDER_DETAIL_BIKE;
                activeStep = 3;
                break;
            case 'Cancelled':
                h1 = "ORDER CANCELLED"
                h2 = "Please contact support"
                sub1 = ""
                sub2 = ""
                // imgSrc = ORDER_DETAIL_CANCELLED;
                activeStep = 6;
                break;
            case 'Delivered':
                h1 = "ORDER DELIVERED"
                h2 = "Rider has delivered your order."
                sub1 = "Your order has been successfully delivered."
                sub2 = ""
                // imgSrc = ORDER_DETAIL_HANDOVER;
                activeStep = 5;
                break;
            default:
                break;
        }

    }

    const [printBusy, setPrintBusy] = useState<boolean>(false)

    const printManifest = () => {
        setPrintBusy(true)
        printLabelAPI(orderDetails.sm_orderid)
        .then((res)=>{
            if(res.response.data.labelUrl){
                window.open(res.response.data.labelUrl, '_blank');
            }else{
                alert("There was some problem while generating label. Please contact support")
            }
        })
        .catch(()=>{
            alert("There was some problem while generating label. Please contact support")
        })
        .finally(()=>{
            setPrintBusy(false)
        })
    }


    useEffect(() => {
        dispatch(getSinglePanIndiaOrder({ orderID: orderID }))
    }, [])

    useEffect(() => {
        console.log(orderDetails)
    }, [orderDetails])

    const repeatOrder = () => {
        dispatch(setPanIndiaRepeatOrder(orderDetails));
        navigate('/orders/panindia/new')
    }

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            {
            fetching?
            <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
                <CircularProgress isIndeterminate />
            </div>
            :
            (
                orderDetails.sm_orderid?
                <>

						<Grid
                            w='100%'
							templateColumns='repeat(3, 1fr)'
							gap={'10px'}
						>
                        <GridItem
                            colSpan={2}
                        >
                            <Card id='shipper-result-card' mb='10px'>
                                <Flex color={endTextColor} fontSize='22px' px='5px' justify='space-between' mb='10px' align='center'>
                                    <Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
                                        {orderDetails?.sm_orderid}
                                    </Text>
                                    {orderDetails.package.some(el=>el.awb) && <Button leftIcon={<HiDocumentText/>} colorScheme='green' size='xs' isLoading={printBusy} onClick={()=>printManifest()}>Download Manifest</Button>}
                                </Flex>
                                {/* <HSeparator mb='5px'/> */}
                                <Box>
                                    <Box display='flex'>
                                        <Box color={endTextColor} w='27px' pt='7px'>
                                        <FaStore />
                                        <Box borderLeft='3px dashed' borderColor={endTextColor} h='calc( 100% - 16px )' mt='3px' ml='6px'></Box>
                                        </Box>
                                        <Box flex={1}  mb='25px'>
                                            <Text fontWeight='700' fontSize='20px'>{orderDetails?.start?.contact?.name}</Text>
                                            <Text mt='-3px'>{orderDetails?.start?.contact?.phone}</Text>
                                            <Text lineHeight={1.1}>
                                                {orderDetails?.start?.address?.houseNo},&nbsp;
                                                {orderDetails?.start?.address?.mapData?.address}<br/>
                                                <b>Pincode:</b>&nbsp;{orderDetails?.start?.address?.mapData?.pincode}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box display='flex'>
                                        <Box color={endTextColor} w='27px' pt='7px'>
                                        <FaMapMarkerAlt />
                                        </Box>
                                        <Box flex={1}>
                                            <Text fontWeight='700' fontSize='20px'>{orderDetails?.end?.contact?.name}</Text>
                                            <Text mt='-3px'>{orderDetails?.end?.contact?.phone}</Text>
                                            <Text lineHeight={1.1}>
                                                {orderDetails?.end?.address?.houseNo},&nbsp;
                                                {orderDetails?.end?.address?.mapData?.address}<br/>
                                                <b>Pincode:</b>&nbsp;{orderDetails?.end?.address?.mapData?.pincode}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>

                            <Card flexDirection='column'>
                                <Flex color={endTextColor} fontSize='20px' px='5px' justify='space-between' mb='10px' align='center'>
                                    <Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
                                        {orderDetails.package.length>1?"Packages":"Package"}
                                    </Text>
                                </Flex>
                                <Box flex={1} display='flex' overflowX='scroll'> 
                                    {orderDetails.package.map((packageEl,i)=>{
                                        return <PackageItem key={'package_'+i} index={i} packageData={packageEl} shipperTrackingBaseURI={orderDetails?.shipperTrackingLinkBaseUrl} />
                                    })}
                                </Box>
                            </Card>
                        </GridItem>
                        <GridItem
                            colSpan={1}
                        >
                            <Card flexDirection='column' mb='10px'>
                                <Flex color={endTextColor} fontSize='20px' px='5px' justify='space-between' mb='10px' align='center'>
                                    <Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
                                        Order Details
                                    </Text>
                                </Flex>
                                <HSeparator mb='5px'/>
                                <Box display='flex'>
                                    <div style={{minWidth:'100px'}}>
                                        <b>Order Type:</b><br/>
                                        <b>Shipper:</b>
                                    </div>
                                    <div>
                                        &nbsp;{orderType}<br/>
                                        &nbsp;{orderDetails?.shipperID}
                                    </div>
                                </Box>
                            </Card>
                            <Card flexDirection='column' mb='10px'>
                                <Flex color={endTextColor} fontSize='20px' px='5px' justify='space-between' mb='10px' align='center'>
                                    <Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
                                        Payment Details
                                    </Text>
                                </Flex>
                                <HSeparator mb='5px'/>
                                <Box display='flex'>
                                    <div style={{minWidth:'100px'}}>
                                        <b>Payment Mode:</b><br/>
                                        <b>Transaction ID:</b>
                                    </div>
                                    <div>
                                        &nbsp;Prepaid Wallet<br/>
                                        &nbsp;{orderDetails?.transactionID}
                                    </div>
                                </Box>
                                <HSeparator my='5px'/>
                                <Box display='flex'>
                                    <div style={{minWidth:'100px'}}>
                                        <b>Order Cost:</b><br/>
                                    </div>
                                    <div>
                                        &nbsp;₹{(orderDetails?.cost).toFixed(2)}
                                    </div>
                                </Box>
                            </Card>
                            {(orderDetails.order_status==='shipper_issue' || orderDetails.package.some(el=> (el.status==='Manifested'||el.status==='Placed'))) &&<Card flexDirection='column' mb='10px'>
                                <Flex color={endTextColor} fontSize='20px' px='5px' justify='space-between' mb='10px' align='center'>
                                    <Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
                                        Actions
                                    </Text>
                                </Flex>
                                <Flex>
                                    {orderDetails.order_status==='shipper_issue' &&
                                        <Button leftIcon={<BiSelectMultiple/>} size='xs' mx='5px' colorScheme='yellow'>
                                            Allocate Shipper
                                        </Button>
                                    }
                                    {
                                        orderDetails.package.some(el=> (el.status==='Manifested'||el.status==='Placed')) &&
                                        <Button onClick={()=>onOpenCancelModal()} leftIcon={<GiCancel/>} size='xs' mx='5px' colorScheme='red'>
                                            Cancel Order
                                        </Button>
                                    }
                                </Flex>
                            </Card>}
                        </GridItem>
                    </Grid>
                    { isOpenCancelModal && <CancelModal
                        isOpen={isOpenCancelModal} onClose={onCloseCancelModal}
                        orderData={orderDetails}
                        callback={(value)=>{
                            dispatch(getSinglePanIndiaOrder({ orderID: orderID }))
                        }}
                    />}
                </>
                :
                <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
                    Order Not Found
                </div>
            )
            }
		</Box>
	);
}
