import { Loader } from "@googlemaps/js-api-loader";
import loadable from '@loadable/component'
import { useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

// import { getAuth } from './app/reducer/auth.reducer';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { getAuth, getUser, getUserData } from "./app/reducer/auth.reducer";
import { CircularProgress, Icon } from "@chakra-ui/react";
import { FaList, FaHome, FaCog, FaTruckLoading, FaStore, FaUsers, FaClipboardList, FaTruckMoving, FaShippingFast, FaTimes, FaMoneyBillWave } from "react-icons/fa";
import { checkLogin, refreshToken } from "app/api/firebase";
import { unwrapResult } from "@reduxjs/toolkit";


import Home from "pages/Home";
import Auth from "pages/Auth";
import { getAllStoreAddresses, getAllSupplierAddresses, getAllCustomerAddresses, getAllSKU } from "app/reducer/general.reducer";
import { getAllHyperlocalOrders } from "app/reducer/hyperlocal.reducer";
import { getAllPanIndiaOrders } from "app/reducer/panindia.reducer";
import { getAllTransactions } from "app/reducer/wallet.reducer";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { MdAdd, MdDoneAll, MdOutlineDashboardCustomize, MdPlaylistAdd } from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { LiaTelegramPlane } from "react-icons/lia";
import { BiSolidHand, BiSolidSave } from "react-icons/bi";
import { PiFilesBold } from "react-icons/pi";
import { RiEBike2Fill } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { IoWallet } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import { AiOutlineFileAdd, AiOutlineFileDone } from "react-icons/ai";
import { HiRefresh } from "react-icons/hi";
import PanIndiaOrderDetails from "pages/panindia/PanIndiaOrderDetails";
import SavedOrdersTable from "pages/panindia/SavedOrdersTable";

export const Loading = () => {
  return <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
    <CircularProgress isIndeterminate />
  </div>;
};
const Dashboard = loadable(() => import("pages/Dashboard"), { fallback: <Loading />, });

const BookSingleApsrtcOrder = loadable(() => import("pages/apsrtc/book-new/BookSingleAPSRTCOrder"), { fallback: <Loading />, });
const OrdersApsrtc = loadable(() => import("pages/apsrtc"), { fallback: <Loading />, });

const BookSinglePanIndiaOrder = loadable(() => import("pages/panindia/book-new/BookSinglePanIndiaOrder"), { fallback: <Loading />, });
const BookBulkPanIndiaOrder = loadable(() => import("pages/panindia/book-new/BookBulkPanIndiaOrder"), { fallback: <Loading />, });
const OrdersPanIndia = loadable(() => import("pages/panindia"), { fallback: <Loading />, });

const Stores = loadable(() => import("pages/saved-data/Stores"),{fallback: <Loading/>,});
const Customers = loadable(() => import("pages/saved-data/Customers"),{fallback: <Loading/>,});
const SKU = loadable(() => import("pages/saved-data/SKU"),{fallback: <Loading/>,});

const Profile = loadable(() => import("pages/profile"),{fallback: <Loading/>,});
const Wallet = loadable(() => import("pages/profile/Wallet"),{fallback: <Loading/>,});

export const routes: RoutesType[] = [
  // {
  //   name: 'Dashboard',
  //   path: '/',
  //   icon: <Icon as={MdOutlineDashboardCustomize} width='25px' height='25px' color='inherit' />,
  //   component: <Home />
  // }
];

export const apsrtcOrderRoutes: RoutesType[] = [
  // {
  //   name: 'Divider',
  //   path: '',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />,
  //   component: <OrdersApsrtc />
  // },
  {
    name: 'Book APSRTC Order',
    path: '/orders/apsrtc/new',
    icon: <AiOutlineFileAdd />,
    component: <BookSingleApsrtcOrder />,
    button: true
    // hidden: true
  },

  {
    name: 'My APSRTC Orders',
    path: '/orders/apsrtc/all-orders',
    component: <OrdersApsrtc />,
    hidden: false
  }
];

export const panindiaOrderRoutes: RoutesType[] = [
  // {
  //   name: 'Divider',
  //   path: '',
  //   icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />,
  //   component: <OrdersPanIndia />
  // },
  {
    name: 'Place New Order',
    path: '/orders/panindia/new',
    icon: <AiOutlineFileAdd/>,
    component: <BookSinglePanIndiaOrder/>,
    button: true
    // hidden: true
  },
  {
    name: 'Place Bulk Orders',
    path: '/orders/panindia/new-bulk',
    icon: <PiFilesBold/>,
    component: <BookBulkPanIndiaOrder/>,
    button: true
    // hidden: true
  },
  {
    name: 'Divider',
    path: '',
    icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />,
    component: <OrdersPanIndia/>
  },
  {
    name: 'My Orders',
    icon: <Icon as={HiClipboardDocumentList} width='20px' height='20px' color='inherit' />,
    component: <OrdersPanIndia/>,
    subRoutes: [
        // {
        //   name: 'Place New Order',
        //   path: '/orders/panindia/new',
        //   component: <BookSinglePanIndiaOrder/>,
        //   icon: <MdAdd/>,
        //   button: true
        // },
        // {
        //   name: 'Place Bulk Orders',
        //   path: '/orders/panindia/new-bulk',
        //   component: <BookBulkPanIndiaOrder/>,
        //   icon: <MdPlaylistAdd/>,
        //   button: true
        // },
        {
          name: 'All',
          path: '/orders/panindia/all-orders',
          icon: <FaList/>,
          component: <OrdersPanIndia/>
        },
        {
          name: 'Ready To Ship',
          path: '/orders/panindia/ready',
          icon: <LiaTelegramPlane/>,
          component: <OrdersPanIndia filter='ready'/>
        },
        {
          name: 'Out for Pick Up',
          path: '/orders/panindia/out-for-pickup',
          icon: <FaTruckMoving/>,
          component: <OrdersPanIndia filter='out-for-pickup'/>
        },
        {
          name: 'In Transit',
          path: '/orders/panindia/in-transit',
          icon: <HiRefresh/>,
          component: <OrdersPanIndia filter='in-transit'/>
        },
        {
          name: 'Out for Delivery',
          path: '/orders/panindia/out-for-delivery',
          icon: <RiEBike2Fill/>,
          component: <OrdersPanIndia filter='out-for-delivery'/>
        },
        {
          name: 'Delivered',
          path: '/orders/panindia/delivered',
          icon: <AiOutlineFileDone/>,
          component: <OrdersPanIndia filter='delivered'/>
        },
        {
          name: 'Cancelled',
          path: '/orders/panindia/cancelled',
          icon: <GiCancel/>,
          component: <OrdersPanIndia filter='cancelled'/>
        },
        {
          name: 'Saved',
          path: '/orders/panindia/saved',
          icon: <BiSolidSave/>,
          component: <SavedOrdersTable/>
        },
        {
          name: 'On Hold',
          path: '/orders/panindia/on-hold',
          icon: <BiSolidHand/>,
          component: <OrdersPanIndia filter='on-hold'/>
        },
    ]
  },
  // {
  //   name: 'Order Fullfillment',
  //   path: '/operator-pickups-and-deliveries',
  //   secondary: true,
  //   icon: <Icon as={FaCheckDouble} width='20px' height='20px' color='inherit' />,
  //   component: <ApsrtcAllOrdersOrderList/>
  // },

  {
    name: 'All Orders',
    path: '/orders/panindia/all-orders',
    component: <OrdersPanIndia/>,
    hidden: true
  },
  {
    name: 'Ready To Ship',
    path: '/orders/panindia/ready',
    component: <OrdersPanIndia filter='Manifested'/>,
    hidden: true
  },
  {
    name: 'Out for Pick Up',
    path: '/orders/panindia/out-for-pickup',
    component: <OrdersPanIndia filter='Out for pickup'/>,
    hidden: true
  },
  {
    name: 'In transit',
    path: '/orders/panindia/in-transit',
    component: <OrdersPanIndia filter='In transit'/>,
    hidden: true
  },
  {
    name: 'Out for Delivery',
    path: '/orders/panindia/out-for-delivery',
    component: <OrdersPanIndia filter='Out for delivery'/>,
    hidden: true
  },
  {
    name: 'Delivered',
    path: '/orders/panindia/delivered',
    component: <OrdersPanIndia filter='Delivered'/>,
    hidden: true
  },
  {
    name: 'Cancelled',
    path: '/orders/panindia/cancelled',
    component: <OrdersPanIndia filter='Cancelled'/>,
    hidden: true
  },
  {
    name: 'Saved',
    path: '/orders/panindia/saved',
    component: <SavedOrdersTable/>,
    hidden: true
  },
  {
    name: 'On Hold',
    path: '/orders/panindia/on-hold',
    component: <OrdersPanIndia filter='shipper_issue'/>,
    hidden: true
  },
  {
    name: 'My Spendings',
    path: '/orders/panindia/spendings',
    icon: <Icon as={FaMoneyBillWave} width='20px' height='20px' color='inherit' />,
    component: <OrdersPanIndia filter='spendings'/>,
  },
];

export const savedDataRoutes: RoutesType[] = [
  {
    name: 'Divider',
    path: '',
    icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />,
    component: <Stores/>
  },
  // {
  //   name: 'My Data',
  //   icon: <Icon as={FaClipboardList} width='20px' height='20px' color='inherit' />,
  //   component: <Stores/>,
  //   subRoutes: [
  //       {
  //         name: 'Stores',
  //         path: '/saved-data/stores',
  //         icon: <FaStore/>,
  //         component: <Stores/>
  //       },
  //       {
  //         name: 'Customers',
  //         path: '/saved-data/customers',
  //         icon: <FaUsers/>,
  //         component: <Customers/>
  //       },
  //       {
  //         name: 'SKUs',
  //         path: '/saved-data/skus',
  //         icon: <ImPriceTags/>,
  //         component: <SKU/>
  //       },
  //       // {
  //       //   name: 'Suppliers',
  //       //   path: '/saved-data/suppliers',
  //       //   icon: <GiGrain/>,
  //       //   component: <SavedData mode='suppliers'/>
  //       // },
  //   ]
  // },
  // {
  //   name: 'Order Fullfillment',
  //   path: '/operator-pickups-and-deliveries',
  //   secondary: true,
  //   icon: <Icon as={FaCheckDouble} width='20px' height='20px' color='inherit' />,
  //   component: <ApsrtcAllOrdersOrderList/>
  // },

  
  {
    name: 'My Stores',
    path: '/saved-data/stores',
    component: <Stores/>,
    icon: <FaStore/>,
    // hidden: true
  },
  {
    name: 'My Customers',
    path: '/saved-data/customers',
    component: <Customers/>,
    icon: <FaUsers/>,
    // hidden: true
  },
  {
    name: 'My SKUs',
    path: '/saved-data/skus',
    component: <SKU/>,
    icon: <ImPriceTags/>,
    // hidden: true
  },
  // {
  //   name: 'Suppliers',
  //   path: '/saved-data/suppliers',
  //   component: <OrdersPanIndia/>,
  //   hidden: true
  // },
];

export const generalRoutes: RoutesType[] = [
  {
    name: 'Divider',
    path: '',
    icon: <Icon as={FaList} width='20px' height='20px' color='inherit' />,
    component: <OrdersPanIndia/>
  },
  // {
  //   name: 'Settings',
  //   path: '/profile',
  //   icon: <Icon as={FaCog} width='20px' height='20px' color='inherit' />,
  //   component: <Profile />
  // },
  {
    name: 'My Wallet',
    path: '/wallet',
    icon: <Icon as={IoWallet} width='20px' height='20px' color='inherit' />,
    component: <Wallet/>
  },
];

let APIKEY: string;
APIKEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''

declare global {
  interface Window {
    gmapsLoaded: boolean;
    loader: Loader;
  }
}

if (!window.gmapsLoaded) {
  console.log('GMAP LOADED');
  const loader = new Loader({
    apiKey: APIKEY,
    version: "weekly",
    libraries: ["places"]
  });
  window.loader = loader;
  window.gmapsLoaded = true;
}

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function RequireAuth({ children }: { children: JSX.Element }) {
  // const [showLoginForm, setShowLoginForm] = useState(true);

  const isAuth = useAppSelector(getAuth);
  // const navigate = useNavigate();
  let location = useLocation();
  // const { height, width } = useViewportSize();
  console.log('RED: ', location);

  if (!isAuth) {
    if (location.pathname && (location.pathname !== '/')) {
      return (<>
        <Navigate to={"/sign-in?redirect=" + location.pathname} state={{ from: location }} replace />
      </>)
    } else {
      return (<>
        <Navigate to={"/sign-in"} state={{ from: location }} replace />
      </>)
    }
  }

  return children;
}

export function Site() {
  const [ready, setReady] = useState<boolean>(false)
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getAuth);
  const userData = useAppSelector(getUser);

  const apsrtcModule = userData?.modules?.includes('apsrtc')?apsrtcOrderRoutes:[]
  const panindiaModule = userData?.modules?.includes('pan-india')?panindiaOrderRoutes:[]

  // const apsrtcModule = apsrtcOrderRoutes
  // const panindiaModule = panindiaOrderRoutes
  const generalModule = generalRoutes
  const savedDataModule = savedDataRoutes

  let usedRoutes = routes.concat(apsrtcModule, panindiaModule, savedDataModule, generalModule);

  useEffect(() => {
    initSite();
    const interval = setInterval(() => {
      refreshToken();
    }, 1200000);
    return () => clearInterval(interval);
  }, []);

  const initSite = async () => {

    await checkLogin().then(async (res: any) => {

      console.log("SAVED AUTH: ", res);
      await dispatch(getUserData())
        .then(unwrapResult).then((res) => {
          dispatch(getAllTransactions());
          dispatch(getAllStoreAddresses({page:1, limit:20}));
          dispatch(getAllSupplierAddresses({page:1, limit:20}));
          dispatch(getAllCustomerAddresses({page:1, limit:20}));
          dispatch(getAllSKU({page:1, limit:20}));
  
          // dispatch(getAllHyperlocalOrders({page: 1, limit: 10}));
          // dispatch(getAllPanIndiaOrders({page: 1, limit: 10}));

        })

    }).finally(() => {
      setReady(true)
    })

  }


  return (
    <Router>
      <ScrollToTopWrapper>
        <main style={{ overflowX: 'hidden' }}>
          <div />
          {
            ready ?
              <Routes>
                <Route path="/sign-in" element={<Auth />} />
                <Route path="/" element={<RequireAuth><Dashboard routes={usedRoutes} /></RequireAuth>}>

                  {usedRoutes.map((route,i)=>{
                    return <Route index={i===0} path={route.path} element={route.component} key={'site_route_'+i} />
                  })}
						      <Route path="/orders/panindia/details/:orderID" element={<PanIndiaOrderDetails />} />
                </Route>
              </Routes>
              :
              <div className="loader-wrapper"><div className="loader-magenta" style={{ zIndex: 8 }} /></div>
          }
        </main>
      </ScrollToTopWrapper>
    </Router>
  )

}

