import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = "http://localhost:8000";
let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";

export interface SearchPayload {
  weight: number;
  service: string;
  start: {
    lat: number;
    lng: number;
    address: string;
    pincode: string;
    state: string;
    city: string;
    country: string;
  };
  end: {
    lat: number;
    lng: number;
    address: string;
    pincode: string;
    state: string;
    city: string;
    country: string;
  };
}

export interface OrderPayload {
  shipperID: string;
  draftOrderID?: string;
  externalOrderID?: string;
  channel?: string;
  vehicle: number;
  start: {
    contact: {
      name: string;
      phone: string;
    };
    address: {
      houseNo: string;
      buildingName: string;
      addressType: string;
      mapData: {
        lat: number;
        lng: number;
        address: string;
        addressType: string;
        pincode: string;
        state: string;
        city: string;
        country: string;
      };
    };
  };
  end: {
    contact: {
      name: string;
      phone: string;
    };
    address: {
      houseNo: string;
      buildingName: string;
      addressType: string;
      mapData: {
        lat: number;
        lng: number;
        address: string;
        addressType: string;
        pincode: string;
        state: string;
        city: string;
        country: string;
      };
    };
  };
  package: {
    sku: {
      sku_id: string;
      sku_name: string;
      sku_value?: number;
      qty: number;
    }[];
    l?: number;
    b?: number;
    h?: number;
    awb?: string;
    type?: string;
    weight: number;
    fragile: boolean;
    category: string;
  }[];
}

export async function searchPanIndiaShippersAPI(payload: SearchPayload) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/search`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function calculateRateAPI(payload) {
  let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/calculate-quick-rate`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function placePanIndiaOrderAPI(payload: OrderPayload) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/createOrder`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
}

export async function saveDraftPanIndiaOrderAPI(
  payload: Partial<OrderPayload>
) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/createDraftOrder`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err });
      });
  });
}

export async function getAllPanIndiaOrdersAPI(query: {
  page?: number;
  limit?: number;
  order_status?: string;
  searchString?: string;
  shippers?: string[];
  senderName?: string;
  startDate?: Date;
  endDate?: Date;
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/getAllOrders`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      params: query,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function getAllPanIndiaDraftOrdersAPI(query: {
  page?: number;
  limit?: number;
  searchString?: string;
  senderName?: string;
  startDate?: Date;
  endDate?: Date;
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/getAllDraftOrders`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      params: query,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function getAllOrdersWithoutPagination(query: {
  page?: number;
  limit?: number;
  order_status?: string;
  searchString?: string;
  shippers?: string[];
  senderName?: string;
  startDate?: Date;
  endDate?: Date;
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });
  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/getAllOrdersWithoutPagination`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      params: query,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function getSinglePanIndiaOrderAPI(id: string | undefined) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/getSingleOrder/${id}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function allocatePanIndiaShipperAPI(payload: {
  sm_orderid: string;
  shipperID: string;
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/reallocateOrder`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function placeBulkordersAPI(payload: {
  shippers?: string[] | undefined;
  minCost?: number | undefined;
  maxCost?: number | undefined;
  orders: any[];
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/createBulkOrder`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function cancelPanIndiaOrderAPI(payload: {
  orderID: string;
  awbs: string[];
}) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/cancelOrder`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
      data: payload,
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}

export async function deletePanIndiaOrderAPI(orderID: string) {
  let authToken = "";
  await checkLogin().then((res: any) => {
    authToken = res.stsTokenManager.accessToken;
  });

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
    axios({
      url: `${baseURL}/business/pan-india/deleteOrder?orderId=${orderID}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + (authToken ? authToken : ""),
      },
    })
      .then((res) => {
        resolve({ response: res.data });
      })
      .catch((err) => {
        reject({ error: err.response.status });
      });
  });
}
