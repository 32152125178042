import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";
import { OrderPayload, SearchPayload } from "./panindia";

let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";
const ecsURL = process.env.REACT_APP_ECS_BASE_URL+"";

export async function searchShippersAPI(payload: SearchPayload) {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/business/search-shipper`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function placeOrderAPI(payload: OrderPayload) {
    let authToken = "";
    await checkLogin().then((res: any) => {
      authToken = res.stsTokenManager.accessToken;
    });
  
    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
        url: `${baseURL}/business/create-order`,
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + (authToken ? authToken : ""),
        },
        data: payload,
      })
        .then((res) => {
          resolve({ response: res.data });
        })
        .catch((err) => {
          reject({ error: err });
        });
    });
}


export async function getAllAgentOrdersAPI(params:{orderStatus?:string, orderType?:string, page?:number, limit?:number, searchString?:string}) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${ecsURL}/get-all-orders`,
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:''),
              stakeholder: "business"
          },
          params: params
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function getAllRequestsAPI(params:{ page?:number, limit?:number}) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${ecsURL}/get-all-saas-request-orders`,
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:''),
              stakeholder: "business"
          },
          params: params
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function updateAgentOrderAPI(payload:any,orderID:string) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${ecsURL}/update-saas-order`,
          method: "PUT",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:''),
              stakeholder: "business"
          },
          data: {payload:payload, sm_orderid:orderID}
      })
      .then((res) => {
          resolve({ response: res })
      })
      .catch((err) => {
          reject({ error: err.response })
      });

  });
}

export async function cancelAgentOrderAPI(orderID:string) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${ecsURL}/cancel-saas-order`,
          method: "POST",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:''),
              stakeholder: "business"
          },
          data: {sm_orderid:orderID}
      })
      .then((res) => {
          resolve({ response: res })
      })
      .catch((err) => {
          reject({ error: err.response })
      });

  });
}

export async function getAllOrdersAPI() {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${baseURL}/upsrtc/getOrderDetails`,
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:'')
          }
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function getSingleIntOrderAPI(orderID:string) {

  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${baseURL}/saas/getSingleIntOrder/${orderID}`,
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:'')
          }
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function createNewOrderAPI(payload:any) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${baseURL}/upsrtc/createOrder`,
          method: "POST",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:'')
          },
          data: payload
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function updateIntOrderAPI(payload:any,orderID:string) {
  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${baseURL}/saas/updateIntOrder`,
          method: "POST",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:'')
          },
          data: {payload:payload, orderID:orderID}
      })
          .then((res) => {
              resolve({ response: res.data })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}

export async function manifestAgentOrderAPI(orderID:string) {

  let authToken = '';
  await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

  return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
      axios({
          url: `${ecsURL}/manifest-pan-order`,
          method: "POST",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer "+(authToken?authToken:''),
              stakeholder: "business"
          },
          data: {sm_orderid: orderID}
      })
          .then((res) => {
              resolve({ response: res })
          })
          .catch((err) => {
              reject({ error: err.response.status })
          });

  });
}