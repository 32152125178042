/* eslint-disable */
import { Badge, Box, Button, Checkbox, CircularProgress, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import 'swiper/scss';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import { useState, useEffect, useRef} from 'react';
import { useMemo } from 'react';
import { FaChevronDown, FaSearch } from 'react-icons/fa';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { BiSelectMultiple } from 'react-icons/bi';
import { getAllPanIndiaDraftOrders, getAllPanIndiaOrders, getPanIndiaDraftOrdersState, getPanIndiaOrdersState, getPanIndiaOrdersTotalPages, setPanIndiaRepeatOrder } from 'app/reducer/panindia.reducer';
import AllocatePanIndiaShipperModal from 'components/modals/AllocatePanIndiaShipperModal';
import Card from 'components/card/Card';
import ChakraSelect from 'components/inputs/ChakraSelect';
import DateRangePicker from 'components/inputs/DateRangePicker';
import { getCustomersAddressesState, getStoreAddressesState } from 'app/reducer/general.reducer';
import ChakraMultiSelect from 'components/inputs/ChakraMultiSelectDownShift';
import { useNavigate } from 'react-router-dom';
import { MdAdd, MdPlaylistAdd } from 'react-icons/md';

const shipperListArr = [
    { value: 'delhivery', label: "Delhivery" },
    { value: 'india-post', label: "India Post" },
    { value: 'xpress-bees', label: "Xpress Bees" },
    { value: 'gati', label: "Gati" },,
    { value: 'trackon', label: "Trackon" },
]

const statusListArr = [
    { label: 'Manifested', value: "Manifested" },
    { label: 'In Transit', value: "In transit" },
    { label: 'Delivered', value: "Delivered" },
    { label: 'Cancelled', value: "Cancelled" },
]
const columnsData = [
    {
      Header: "Order Date",
      accessor: "createdAt",
      showInTable: true
    },
    {
      Header: "Sender",
      accessor: "start",
      showInTable: true
    },
    {
      Header: "Destination",
      accessor: "end",
      showInTable: true
    },
    {
      Header: "Package",
      accessor: "package",
      showInTable: false
    },
    {
      Header: "Action",
      accessor: "start.contact",
      showInTable: true
    },
];

function OrdersTableRow(props:any) {
    const navigate = useNavigate();
    const { row, selected, rowIndex, toggleSelection, orderPage } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'white800');
	const { isOpen: isOpenAllocateModal, onOpen: onOpenAllocateModal, onClose: onCloseAllocateModal } = useDisclosure()
    const dispatch = useAppDispatch();
    const [busy, setBusy] = useState(false);
    const [assignMode, setAssignMode] = useState(false);

    const onStatusChange = (orderID: string, status:string) => {
        // setBusy(true)
        // dispatch(updateIntOrder({payload: {state:status}, orderID:orderID}))
        // .then(unwrapResult).finally(()=>{
        //     setBusy(false)
        // });

    }

    const placeOrder = (data:any) => {
        dispatch(setPanIndiaRepeatOrder(data));
        navigate('/orders/panindia/new')
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
      
    function formatDate(date) {
        return [
          padTo2Digits(date.getDate()),
          padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
    }
    

    return(
        <Tr {...row.getRowProps()} borderTop='1px solid #cacfdd' zoom={0.7}>
            <Td
                verticalAlign='top'
                fontSize={{ sm: '14px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
            >
                <Text fontWeight='700' ml='10px' color={textColor} fontSize='xs' textTransform='capitalize'>
                    {((orderPage-1)*10)+rowIndex+1}
                </Text>
            </Td>
            {row.cells.map((cell, index) => {
                let data;
                switch (cell.column.Header) {
                    // case 'Select':
                    //     data = (
                    //         <Checkbox
                    //             borderWidth='2px' borderColor='#a1aec0' borderRadius='2px'
                    //             ml='10px'
                    //             checked={selected}
                    //             onChange={() => toggleSelection(!selected,rowIndex)}
                    //         />
                    //     );
                    //     break;
                    case 'Order Date':
                        const dateObj = new Date(cell.value)
                        const timeString = dateObj.toTimeString().split(' ')[0]
                        data = (
                            <Text color={textColor} fontSize='xs' textTransform='capitalize'>
                                <Text fontWeight='700'>Created:</Text>&nbsp;{formatDate(dateObj)}
                                &nbsp;{timeString.split(':')[0]+":"+timeString.split(':')[1]}
                            </Text>
                        );
                        break;
                    case 'Sender':
                        data = (
                            <>
                            <Text color={textColor} fontWeight='700' fontSize='sm' textTransform='capitalize'>
                                {cell.value?.contact?.name}
                            </Text>
                            {/* <Box display='flex'>
                                <Text color={textColor} flex={1} fontSize='sm'>
                                    {cell.value.contact.phone}
                                </Text>
                                <IconButton
                                    variant="ghost"
                                    colorScheme="brand"
                                    aria-label="Call Sender"
                                    borderRadius="10px"
                                    size="sm"
                                    icon={<FaPhone />}
                                />
                            </Box> */}
                            </>
                        );
                        break;
                    case 'Destination':
                        data = (
                            <>
                            <Text color={textColor} fontWeight='700' fontSize='sm' textTransform='capitalize'>
                                {cell.value?.contact?.name}
                            </Text>
                            {/* <Box display='flex'>
                                <Text color={textColor} flex={1} fontSize='sm'>
                                    {cell.value.contact.phone}
                                </Text>
                                <IconButton
                                    variant="ghost"
                                    colorScheme="brand"
                                    aria-label="Call Reciepient"
                                    borderRadius="10px"
                                    size="sm"
                                    icon={<FaPhone />}
                                />
                            </Box> */}
                            </>
                        );
                        break;
                    case 'Action':
                        data = (
                            <Button
                            onClick={()=>placeOrder(cell.row?.original)}
                            variant={'outline'}
                            colorScheme='blue'
                            textTransform='capitalize'
                            size='sm'
                        >
                            Place Order
                        </Button>
                        )
                        break;
                
                    default:
                        break;
                }
                return (
                    cell.column.showInTable && <Td
                        verticalAlign='top'
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    >
                        {data}
                    </Td>
                );
            })}

			{ isOpenAllocateModal && <AllocatePanIndiaShipperModal
				isOpen={isOpenAllocateModal} onClose={onCloseAllocateModal}
                orderData={row.original}
				callback={(value)=>{
                    return
				}}
			/>}
        </Tr>
    )
}

export default function SavedOrdersTable(props: { setselectedOrdersArr?: Function }) {
	const { setselectedOrdersArr } = props;

    const navigate = useNavigate();

    const tableData = useAppSelector(getPanIndiaDraftOrdersState);
    const totalPages = useAppSelector(getPanIndiaOrdersTotalPages);

	const storeAddresses = useAppSelector(getStoreAddressesState);
	const customerAddresses = useAppSelector(getCustomersAddressesState);

    //filters
    const [selectedDatesFilter, setSelectedDatesFilter] = useState<Date[]>([]);
    const [shipperFilter, setShipperFilter] = useState<string[]>([]);
    const [senderFilter, setSenderFilter] = useState<string>('');
    // const [statusFilter, setStatusFilter] = useState<string>('');

	const columns = useMemo(() => columnsData, [ columnsData ]);
	const data = useMemo(() => tableData, [ tableData ]);

    const firstLoad = useRef(true)
    const searchInputFirstLoad = useRef(false);

    const [fetching, setFetching] = useState<boolean>(false)
    const [orderPage, setOrderPage] = useState<number>(1)
    const [orderLimit, setOrderLimit] = useState<number>(10)
    const [searchString, setSearchString] = useState<string>('')

    const [selectedArr,setSelectedArr] = useState<any[]>([])

    useEffect(() => {
        let tempArr:any[] = []
        tableData.forEach((el)=>{tempArr.push(false)})
        setSelectedArr(tempArr);
    }, [tableData])
    
    // useEffect(() => {
    //     let tempArr:any[] = []
    //     selectedArr.forEach((el,i)=>{if(el){tempArr.push(tableData[i])}})
    //     setselectedOrdersArr(tempArr)
    // }, [selectedArr])

    const dispatch = useAppDispatch();

    useEffect(() => {
        setFetching(true)
        dispatch(getAllPanIndiaDraftOrders({
            page: orderPage,
            limit: orderLimit,
            searchString:searchString?searchString:undefined,
            senderName: senderFilter?senderFilter:undefined,
            startDate: selectedDatesFilter[0]?selectedDatesFilter[0]:undefined,
            endDate: selectedDatesFilter[1]?selectedDatesFilter[1]:undefined
        }))
        .finally(()=>setFetching(false))

    }, [orderPage, orderLimit, shipperFilter, senderFilter, selectedDatesFilter])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            if (searchInputFirstLoad.current) {
                setFetching(true)
                dispatch(getAllPanIndiaDraftOrders({
                    page: orderPage,
                    limit: orderLimit,
                    searchString:searchString?searchString:undefined,
                    senderName: senderFilter?senderFilter:undefined,
                    startDate: selectedDatesFilter[0]?selectedDatesFilter[0]:undefined,
                    endDate: selectedDatesFilter[1]?selectedDatesFilter[1]:undefined
                }))
                .finally(()=>setFetching(false))
                .catch(() => {

                })
            } else {
                searchInputFirstLoad.current = true
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchString])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            if (searchInputFirstLoad.current) {
                setFetching(true)
                dispatch(getAllPanIndiaDraftOrders({
                    page: orderPage,
                    limit: orderLimit,
                    searchString:searchString?searchString:undefined,
                    senderName: senderFilter?senderFilter:undefined,
                    startDate: selectedDatesFilter[0]?selectedDatesFilter[0]:undefined,
                    endDate: selectedDatesFilter[1]?selectedDatesFilter[1]:undefined
                }))
                .finally(()=>setFetching(false))
                .catch(() => {

                })
            } else {
                searchInputFirstLoad.current = true
            }
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [shipperFilter])


	const tableInstance = useTable(
		{
			columns,
			data
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, initialState } = tableInstance;
	// initialState.pageSize = orderLimit;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('secondaryGray.500', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const textColorSecondary = useColorModeValue('secondaryGray.700', 'white800');

    const changeSelectedArr = (value:boolean,index:number) => {
        let tempArr:any[] = []
        selectedArr.forEach((el)=>tempArr.push(el))
        tempArr[index] = value
        setSelectedArr(tempArr)
    }

    const allChecked = selectedArr.every(Boolean)
    const isIndeterminate = selectedArr.some(Boolean) && !allChecked

	return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Box display='flex' mb='10px'>
                <Button onClick={()=>navigate('/orders/panindia/new')} leftIcon={<MdAdd/>} colorScheme='green' mx='5px'>Place New Order</Button>
                <Button onClick={()=>navigate('/orders/panindia/new-bulk')} leftIcon={<MdPlaylistAdd/>} colorScheme='green' mx='5px'>Place Bulk Orders</Button>
            </Box>
            <Card className='zoom-out' display='flex' flexDirection='row' alignItems='center' px='20px' mb='10px'>
                <FormControl mx='10px' flex={1}>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Search:</FormLabel>
                    <InputGroup>
                        <InputLeftElement>
                            <FaSearch size='15px' style={{marginBottom:'7px'}}/>
                        </InputLeftElement>
                        <Input size='sm' borderRadius='10px' placeholder='Search by OrderID or AWB' value={searchString} onChange={e=>setSearchString(e.target.value)} />
                    </InputGroup>
                </FormControl>
                <FormControl mx='10px' maxW='250px'>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Date Filter:</FormLabel>
                    <DateRangePicker
                        selectedDates={selectedDatesFilter}
                        setSelectedDates={setSelectedDatesFilter}
                        placeHolder='Select Date Range'
                    />
                </FormControl>
                <FormControl mx='10px' maxW='150px'>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Shipper Filter:</FormLabel>
                    <ChakraMultiSelect
                        value={shipperFilter}
                        onSelect={(value)=>{setShipperFilter(value)}}
                        placeHolder="Select Shipper"
                        canClear
                        options={shipperListArr}
                    />
                    {/* <Select borderRadius='10px' placeholder="Select Shipper" size='sm'>
                        {shipperListArr.map((type,i)=>{return <option key={'pt_opt_'+i} value={type.value}>{type.label}</option>})}
                    </Select> */}
                </FormControl>
                <FormControl mx='10px' maxW='150px'>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Sender Filter:</FormLabel>
                    <ChakraSelect
                        value={senderFilter}
                        onSelect={(value)=>{setSenderFilter(value)}}
                        placeHolder="Select Sender"
                        size='sm' canClear
                        options={storeAddresses.map(item=>{return {label:(item.name+(item.nickname?` (${item.nickname})`:"")+" - "+item.mapData.city),value:item.name}})}
                    />
                    {/* <Select borderRadius='10px' placeholder="Select Sender" size='sm'>
                        {shipperListArr.map((type,i)=>{return <option key={'pt_opt_'+i} value={type.value}>{type.label}</option>})}
                    </Select> */}
                </FormControl>
                {/* <FormControl mx='10px' maxW='150px'>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Recipient Filter:</FormLabel>
                    <ChakraSelect
                        onSelect={(value)=>{return}}
                        placeHolder="Select Recipient"
                        size='sm'
                        options={customerAddresses.map(item=>{return {label:(item.name+(item.nickname?` (${item.nickname})`:"")+" - "+item.mapData.city),value:item.name}})}
                    />
                </FormControl> */}
                {/* <FormControl mx='10px' maxW='150px'>
                    <FormLabel fontSize='13px' pl='10px' mb={0}>Status Filter:</FormLabel>
                    <ChakraSelect
                        onSelect={(value)=>{return}}
                        placeHolder="Select Shipper"
                        size='sm'
                        options={statusListArr}
                    />
                </FormControl> */}
            </Card>
            <Card className='zoom-out' flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }} minHeight='calc( 100vh - 170px)'>
                <Table {...getTableProps()} variant='unstyled' color='gray.500' mb='24px' size="sm">
                    <Thead>
                        {headerGroups.map((headerGroup, index) => (
                            <Tr {...headerGroup.getHeaderGroupProps()} key={'order-head'+index} mb='10px'>
                                <Th
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='center'
                                        align='center'
                                        fontSize={{ sm: '10px', lg: '12px' }}
                                        color='gray.400'>
                                        Sr No
                                    </Flex>
                                </Th>
                                {headerGroup.headers.map((column, index) => (
                                    column.render('showInTable') && <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        pe='10px'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Flex
                                            justify='space-between'
                                            align='center'
                                            fontSize={{ sm: '10px', lg: '12px' }}
                                            color='gray.400'>
                                            {column.render('Header')}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                        !fetching && page.length>0 &&
                            page.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <OrdersTableRow row={row} rowIndex={index} orderPage={orderPage} selected={selectedArr[index]} toggleSelection={changeSelectedArr} textColor={textColor} key={'order-row'+index}/>
                                );
                            })
                        }
                    </Tbody>
                </Table>
                {
                    fetching && 
                    <div style={{ columnSpan:'all', height: '300px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
                        <CircularProgress isIndeterminate />
                    </div>
                }
                { !fetching && page.length===0 &&
                    <div style={{ columnSpan:'all', height: '300px', textAlign:'center', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
                        No Orders to Show
                    </div>
                }
                <Box  flex={1}/>
                <HSeparator my='10px'/>
                <Box display='flex' px='20px' justifyContent='space-between'>
                    <Box>
                        page {orderPage} of {totalPages}
                    </Box>
                    <Box>
                        <Button size='sm' my='5px' variant='ghost' onClick={() => setOrderPage(1)} disabled={(orderPage === 1) || fetching}><HiChevronDoubleLeft/></Button>
                        <Button size='sm' my='5px' variant='ghost' onClick={() => setOrderPage(orderPage - 1)} disabled={(orderPage - 1 <= 0) || fetching}><HiChevronLeft/></Button>
                        {orderPage - 2 > 0 && <Button size='sm' my='5px' variant='ghost' disabled={fetching} onClick={() => setOrderPage(orderPage - 2)}>{orderPage - 2}</Button>}
                        {orderPage - 1 > 0 && <Button size='sm' my='5px' variant='ghost' disabled={fetching} onClick={() => setOrderPage(orderPage - 1)}>{orderPage - 1}</Button>}
                        <Button size='sm' my='5px' variant='brand'>{orderPage}</Button>
                        {totalPages >= orderPage + 1 && <Button size='sm' my='5px' variant='ghost' disabled={fetching} onClick={() => setOrderPage(orderPage + 1)}>{orderPage + 1}</Button>}
                        {totalPages >= orderPage + 2 && <Button size='sm' my='5px' variant='ghost' disabled={fetching} onClick={() => setOrderPage(orderPage + 2)}>{orderPage + 2}</Button>}
                        <Button size='sm' my='5px' variant='ghost' onClick={() => setOrderPage(orderPage + 1)} disabled={(totalPages < orderPage + 1) || fetching}><HiChevronRight/></Button>
                        <Button size='sm' my='5px' variant='ghost' onClick={() => setOrderPage(totalPages)} disabled={(totalPages <= orderPage) || fetching}><HiChevronDoubleRight/></Button>
                    </Box>
                    <Box>
                        View&nbsp;
                        <Menu>
                            <MenuButton
                                as={Button}
                                variant="link" 
                                size='xs'
                                rightIcon={<Icon as={FaChevronDown} />}
                            >
                                <Text color={textColor} fontSize='sm' fontWeight='700'>
                                {orderLimit}
                                </Text>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={()=>setOrderLimit(10)}>
                                <Text fontWeight={600} color={textColorSecondary}>
                                    10
                                </Text></MenuItem>
                                <MenuItem onClick={()=>setOrderLimit(20)}>
                                <Text fontWeight={600} color={textColorSecondary}>
                                    20
                                </Text></MenuItem>
                                <MenuItem onClick={()=>setOrderLimit(50)}>
                                <Text fontWeight={600} color={textColorSecondary}>
                                    50
                                </Text></MenuItem>
                                <MenuItem onClick={()=>setOrderLimit(100)}>
                                <Text fontWeight={600} color={textColorSecondary}>
                                    100
                                </Text></MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Box>
            </Card>
		</Box>
	);
}
