import { useEffect, useState } from "react";
import 'swiper/scss';

import { searchPanIndiaShippersAPI } from "app/api/panindia";

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Text, Image, Alert, AlertIcon, Box, Button, ModalFooter, Flex, Icon, useColorModeValue, useToast, CircularProgress } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import Card from "components/card/Card";
import { FaTruckLoading } from "react-icons/fa";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { TiStarFullOutline, TiStarHalfOutline } from "react-icons/ti";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { allocateShipper, getPanIndiaOrderFetchingState, placePanIndiaOrder } from "app/reducer/panindia.reducer";

function PanIndiaShipperItem(props) {

	const {shipperData, onBook} = props;
	const {shipper,estimate} = shipperData;

    const fetching = useAppSelector(getPanIndiaOrderFetchingState);

	const [busy, setBusy] = useState<boolean>(false);

	return (
		<Card borderTop='2px solid #E9ECF2' width='initial' borderRadius={0} display='flex' flexDirection='row' alignItems='center' height='100px' mx='20px'>
			<Box height='30px' width='120px'>
				<Image maxWidth='120px' maxHeight='30px' src={shipper.logoURL} alt={shipper.fullName} />
			</Box>
			<Box flex={1} fontSize='20px' fontWeight={600} mx='10px'>
				{shipper.fullName}
			</Box>
			<Box flex={1} fontWeight={600} mx='10px'>
				Ratings:
				<Box fontSize='20px' fontWeight={600}display='flex'>
					<TiStarFullOutline/>
					<TiStarFullOutline/>
					<TiStarFullOutline/>
					<TiStarFullOutline/>
					<TiStarHalfOutline/>
					&nbsp;
				</Box>
			</Box>
			<Box flex={1} fontWeight={600} mx='10px'>
				Estimated Time:
				<Box fontSize='20px' fontWeight={600}>
					3-5 Days
				</Box>
			</Box>
			<Box flex={1} fontWeight={600} mx='10px'>
				Cost:
				<Box fontSize='20px' fontWeight={600} display='flex' alignItems='center'>
					<HiOutlineCurrencyRupee/>&nbsp;{parseFloat((estimate.cost + estimate.b2bUserMarkupCost) + (estimate.platformCharges)).toFixed(2)}
				</Box>
			</Box>
			<Button isLoading={busy} colorScheme='green' disabled={fetching}
				onClick={()=>{
					setBusy(true);
					onBook(shipperData,()=>setBusy(false))
				}}
			>
				Place Order
			</Button>
		</Card>
	)
}

export default function AllocatePanIndiaShipperModal(props): JSX.Element {

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	const startTextColor = useColorModeValue('green.600', 'green.100');
	const endTextColor = useColorModeValue('brand.500', 'blue.100');
	const errorTextColor = useColorModeValue('red.600', 'red.100');

	const toast = useToast()
    const dispatch = useAppDispatch()

    const {isOpen, onClose, orderData, callback } = props;

    const [shipperResults, setShipperResults] = useState<any[]>([])

    const [vehicleType, setVehicleType] = useState<number>(0);
    const [busySearch, setBusySearch] = useState<boolean>(true);

    useEffect(() => {
        searchShippers()
    }, [])
    
    const searchShippers = () => {

        const startObj = orderData.start.address.mapData

        const endObj = orderData.end.address.mapData

        let totalWeight = 0;
        orderData.package.forEach((el,i)=>{
            totalWeight = totalWeight + el.weight
        })

        const searchParams = {
            weight: totalWeight,
            start: startObj,
            end: endObj,
            service: 'express-delivery'
        }

        // console.log(data);
        setBusySearch(true);
        searchPanIndiaShippersAPI(searchParams)
        .then((res) => {
            console.log(res);
            setShipperResults(res.response.data)

            // dispatch(clearOrderDetail());
        }).catch((e) => {
            console.log(e);
			toast({
				title: 'Could not find shippers for you.',
				description: e.data.message as string+". Please try again later or contact support for help.",
				status: 'error',
				position: 'bottom-right',
				isClosable: true,
			})
            // alert("No Shipper Avaialable for this order")
        }).finally(() => {
            setBusySearch(false);
        })
    }

	const placeOrder = (shipperData:any, callback:Function) => {

		// setBusy(true);
		const orderParams = {
		sm_orderid: orderData.sm_orderid,
		  shipperID: shipperData.shipper.name,
		}
  
		dispatch(allocateShipper(orderParams)).then(unwrapResult)
		.then((res)=>{
			console.log(res);
			toast({
				title: 'New Shipper Allocated to Order - '+res.data.sm_orderid,
				description: `Order allocated successfully to ${shipperData.shipper.fullName}.`,
				status: 'success',
				variant: 'subtle',
				position: 'bottom-right',
				isClosable: true,
			})
            if(callback){
                callback();
            }
            onClose();
            setShipperResults([]);

		}).catch((e)=>{
		  console.log(e);
		  if(e.data.message==='Insufficient balance'){
			toast({
				title: 'Insufficient Balance.',
				description: "Your wallet does not have enough balance to place this order. Please recharge & try again.",
				status: 'warning',
				position: 'bottom-right',
				isClosable: true,
			})
		  }else{
			toast({
				title: 'Something went wrong.',
				description: e.data.message as string+". Please try another shipper or contact support for help.",
				status: 'error',
				position: 'bottom-right',
				isClosable: true,
			})
		  }
		}).finally(()=>{
		//   setBusy(false);
		  callback()
		})

	}
    return (
        <Modal 
            closeOnOverlayClick={true}
            isCentered motionPreset='slideInBottom' size='full'
            isOpen={isOpen} onClose={()=>onClose()}
        >
            <ModalOverlay
            backdropFilter='blur(10px)'
            />
            <ModalContent>
                <ModalHeader display='flex'>
                    <Text flex={1} fontSize='22px' fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
					    Allocate New Shipper
				    </Text>
                </ModalHeader>
                <ModalBody mx='40px' my='20px' bg='#dff2ff' p='20px' borderRadius='20px'>
                    <Alert status='info' fontSize='12px' borderRadius='10px' mb='10px' style={{zoom:1.2}}>
						<AlertIcon />
						<Box>
                            Sometimes, due to technical reasons previously allocated shipper is not able to process the order. You can choose to cancel the order or reallocate it to different Shipper.<br/>
							<b>Your wallet will be credite or debited according to cost difference of new shipper.</b>
						</Box>
					</Alert>
					{
						busySearch?
						<Card display='flex' flexDirection='column'>
							<Flex color={endTextColor} fontSize='22px' px='5px' justify='space-between' mb='10px' align='center'>
								<Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
									Searching Shippers...
								</Text>
							</Flex>
							<div style={{ columnSpan:'all', height: '300px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontWeight:800 }}>
								<CircularProgress isIndeterminate />
							</div>
						</Card>
						:
						<Card display='flex' flexDirection='column'>
							<Flex color={endTextColor} fontSize='22px' px='5px' justify='space-between' mb='10px' align='center'>
								<Icon mr='10px' as={FaTruckLoading} />
								<Text flex={1} fontWeight='700' lineHeight='100%' display='flex' flexDirection='row'>
									{shipperResults.length}&nbsp;Shippers Available
								</Text>
							</Flex>
							<div style={{fontWeight:600}}>Select a shipper to place your order.</div>
							<Box flex={1} overflowY='scroll' height='calc(100vh - 312px)'> 
								{shipperResults.map((shipperEl,i)=>{
									return <PanIndiaShipperItem key={'shipper_result_'+i} shipperData={shipperEl} onBook={placeOrder}/>
								})}
							</Box>
						</Card>

					}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' onClick={()=>onClose()} mr='10px'>
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>

    );

}
