import { Box, Button, FormControl, FormLabel, Input, InputGroup, Text, InputRightElement, List, Select, useColorModeValue, InputLeftElement, Checkbox } from '@chakra-ui/react';
import React, { useState } from 'react';
import {render} from 'react-dom'
import Downshift from 'downshift'
import { FaBuilding, FaCalendarAlt, FaChevronDown, FaChevronUp, FaLocationArrow, FaPlus, FaSearch, FaTimesCircle } from 'react-icons/fa';
import { HSeparator } from 'components/separator/Separator';
import { IoPricetags } from 'react-icons/io5';
import RangeCalendar from 'components/calendar/RangeCalendar';
import { OnDateSelected } from 'types/commonTypes';
import { format } from 'util';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';

export default function ChakraMultiSelectDownShift(props:{value?:any[], options:any[], onSelect:Function, placeHolder?:string, canClear?:boolean, disabled?:boolean, icon?:any}) {
	const { value=[], options, onSelect, placeHolder, canClear, disabled, icon } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgColor = useColorModeValue('white', '#111c44');
	const bgColorBtn = useColorModeValue('white', '#253368');
	const borderColor = useColorModeValue('rgba(0,0,0,0.1)', 'white300');

    const onCheckOption = (optionValue:string) => {

        if(value.includes(optionValue)){
            let tempArr:any[] = [...value]
            // multiShippersArr.forEach((el)=>tempArr.push(el))
            tempArr = tempArr.filter(e => e !== optionValue)
            onSelect(tempArr)
        }else{
            let tempArr:any[] = [...value]
            // multiShippersArr.forEach((el)=>tempArr.push(el))
            tempArr.push(optionValue)
            onSelect(tempArr)
        }
    }


    return(
        <Downshift
          onChange={selection => {
            }
          }
          itemToString={item => (item ? item.sku_name : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
          }) => (
                <>
                <div style={{display: 'inline-block', width:'100%'}} {...getRootProps(undefined, {suppressRefError: true})}>

                    <Button
                        borderRadius='0px'
                        rightIcon={(isOpen && !disabled)?<FaChevronUp/>:<FaChevronDown />}
                        disabled={disabled} size='sm'  variant='outline'
                        {...getToggleButtonProps()} isFullWidth fontWeight={'normal'}
                        color={textColor} backgroundColor={bgColor}
                        textTransform='capitalize'
                    >
                        <Text textAlign='start' flex={1}>{value[0]?(value.length===1?options.find((element) => element.value === value[0]).label:value.length+" Selected"):(placeHolder?placeHolder:'Select an option')}</Text>
                    </Button>
                </div>
                <Box
                    display={(isOpen && !disabled)?'inherit':'none'}
                    position='absolute'
                    width='100%'
                    zIndex={999}
                    onMouseDown={e=>{e.preventDefault();e.stopPropagation()}}
                >
                    <List
                        bg={bgColor}
                        borderRadius='4px'
                        border={isOpen && '1px solid'}
                        borderColor={borderColor}
                        boxShadow='6px 5px 8px rgba(0,50,30,0.02)'
                        {...getMenuProps()}
                        maxH='400px'
                        overflowY='scroll'
                        display='flex'
                        flexDir='column'
                        alignItems='start' pr='5px'
                    >
                        {isOpen && canClear && 
                            <li
                            >
                                <Button 
                                    leftIcon={<FaTimesCircle/>}
                                    onClick={()=>onSelect([])}
                                    variant='ghost' color='crimson'
                                    fontWeight='normal'
                                >
                                   Clear
                                </Button>
                            </li>
                        }
                        {isOpen
                        ? options
                            .map((el, index) => (
                                <li
                                {...getItemProps({
                                    key: el.value,
                                    index,
                                    item: el,
                                    style: {
                                        height:'35px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        textTransform:'capitalize'
                                    },
                                })}
                                >
                                    <Button 
                                        onClick={(e)=>{e.preventDefault();e.stopPropagation();onCheckOption(el.value)}}
                                        variant='ghost' fontWeight={value.includes(el.value)?600:'normal'}
                                        color={value.includes(el.value)?'#422AFB':undefined}
                                        leftIcon={value.includes(el.value)?<RiCheckboxFill/>:<RiCheckboxBlankLine/>}
                                    >
                                    {el.label}
                                    </Button>
                                </li>
                            ))
                        : null
                        }
                    </List>
                </Box>
            </>
          )}
        </Downshift>
        )
}
