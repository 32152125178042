import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllTransactionsAPI } from 'app/api/wallet';

import { RootState } from '../store';

export interface WalletState {
    transactions: any[],
    balance: number,
    fetching: boolean
}

const initialState: WalletState = {
    transactions: [],
    balance: 0,
    fetching: false
}

export const getAllTransactions = createAsyncThunk(
    'wallet/getAllTransactions',
    async () => {
        const response = await getAllTransactionsAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTransactions.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllTransactions.fulfilled, (state, action) => {
                const { balance, data } = action.payload.data;
                state.balance = balance
                state.transactions = data.transactions
                state.fetching = false
            })
            .addCase(getAllTransactions.rejected, (state) => {
                state.fetching = false
            })

    }
})

export const { } = walletSlice.actions

export const getAllTransactionsState = (state: RootState) => state.wallet.transactions
export const getWalletBalanceState = (state: RootState) => state.wallet.balance
export const getHyperlocalOrderFetchingState = (state: RootState) => state.wallet.fetching

export default walletSlice.reducer;