import { Box, Flex } from "@chakra-ui/react";
import { useState } from "react";
import { Props as DayzedHookProps } from 'dayzed';
import { CalendarConfigs, PropsConfigs, OnDateSelected } from "types/commonTypes";
import { format } from "util";
import { Month_Names_Short, Weekday_Names_Short } from "utils/calenderUtils";
import { CalendarPanel } from "components/calendar/calendarPanel";

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps;
  configs: CalendarConfigs;
  propsConfigs?: PropsConfigs;
  selected?: Date | Date[];
}

const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  selected,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return;
    }
    setHoveredDate(date);
  };

  const isInRange = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return false;
    }
    let firstSelected = selected[0];
    if (selected.length === 2) {
      let secondSelected = selected[1];
      return firstSelected < date && secondSelected > date;
    } else {
      return (
        hoveredDate &&
        ((firstSelected < date && hoveredDate >= date) ||
          (date < firstSelected && date >= hoveredDate))
      );
    }
  };

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  );
};

export default function RangeCalendar(props) {
  const { selectedDates, handleOnDateSelected } = props;
  
  // eventually we want to allow user to freely type their own input and parse the input
  let intVal = selectedDates[0]
    ? `${selectedDates[0].toLocaleString().split(',')[0]}`
    : '';
  intVal += selectedDates[1]
    ? ` - ${selectedDates[1].toLocaleString().split(',')[0]}`
    : '';

  return (
    <Box>
      {/* <Box>{intVal}</Box> */}
      <RangeCalendarPanel
        selected={selectedDates}
        dayzedHookProps={{
          showOutsideDays: false,
          onDateSelected: handleOnDateSelected,
          selected: selectedDates,
          monthsToDisplay: 2,
        }}
        configs={{
          dateFormat: 'MM/dd/yyyy',
          monthNames: Month_Names_Short,
          dayNames: Weekday_Names_Short,
          firstDayOfWeek: 0,
        }}
      />
    </Box>
  );
};