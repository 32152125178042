import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllHyperlocalOrdersAPI, getSingleHyperlocalOrderAPI, OrderPayload, placeHyperlocalOnDemandOrderAPI, searchHyperlocalOnDemandShippersAPI, SearchPayload } from 'app/api/hyperlocal';
import { RootState } from '../store';

export interface GeneralState {
    orders: any[],
    page: number,
    limit: number,
    fetchingOrders: boolean,
    orderDetails: any
}

const initialState: GeneralState = {
    orders: [],
    page: 1,
    limit: 20,
    fetchingOrders: false,
    orderDetails: {}
}

export const getAllHyperlocalOrders = createAsyncThunk(
    'hyperlocal/getAllHyperlocalOrders',
    async (query:{page?: number, limit?: number}) => {
        const response = await getAllHyperlocalOrdersAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getSingleHyperlocalOrder = createAsyncThunk(
    'hyperlocal/getSingleHyperlocalOrder',
    async ({ orderID }: { orderID: string | undefined }) => {
        const response = await getSingleHyperlocalOrderAPI(orderID);
        return response.response;
    }
);

export const placeHyperlocalOnDemandOrder = createAsyncThunk(
    'hyperlocal/placeHyperlocalOnDemandOrder',
    async (data: OrderPayload, {rejectWithValue}) => {

        try {
            const response = await placeHyperlocalOnDemandOrderAPI(data);
            return response.response;
          } catch (err:any) {
            return rejectWithValue(err.error.response)
          }
    }
);

export const searchHyperlocalOnDemandShippers = createAsyncThunk(
    'hyperlocal/searchHyperlocalOnDemandShippers',
    async (data: SearchPayload) => {
        const response = await searchHyperlocalOnDemandShippersAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const hyperlocalSlice = createSlice({
    name: 'hyperlocal',
    initialState,
    reducers: {
        setRepeatOrder(state, action) {
            state.orderDetails = action.payload
        },
        clearOrderDetail(state) {
            state.orderDetails = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllHyperlocalOrders.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(getAllHyperlocalOrders.fulfilled, (state, action) => {
                const { results, next } = action.payload.data;
                let orders = state.orders
                results.forEach(element => {
                    orders.push(element);
                });
                state.orders = orders;
                if(next){
                    state.page = next.page;
                }else{
                    state.page = 0;
                }
                state.fetchingOrders = false
            })
            .addCase(getAllHyperlocalOrders.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(getSingleHyperlocalOrder.pending, (state) => {
                state.orderDetails = {};
                state.fetchingOrders = true
            })
            .addCase(getSingleHyperlocalOrder.fulfilled, (state, action) => {
                state.orderDetails = action.payload.data;
                state.fetchingOrders = false
            })
            .addCase(getSingleHyperlocalOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

            .addCase(placeHyperlocalOnDemandOrder.pending, (state) => {
                state.fetchingOrders = true
            })
            .addCase(placeHyperlocalOnDemandOrder.fulfilled, (state, action) => {
                let orders = state.orders
                orders.push(action.payload.data);
                state.orders = orders;
                state.fetchingOrders = false
            })
            .addCase(placeHyperlocalOnDemandOrder.rejected, (state) => {
                state.fetchingOrders = false
            })

    }
})

export const { setRepeatOrder, clearOrderDetail } = hyperlocalSlice.actions

export const getHyperlocalPageState = (state: RootState) => state.hyperlocal.page
export const getHyperlocalOrdersState = (state: RootState) => state.hyperlocal.orders
export const getHyperlocalOrderDetailState = (state: RootState) => state.hyperlocal.orderDetails
export const getHyperlocalOrderFetchingState = (state: RootState) => state.hyperlocal.fetchingOrders

export default hyperlocalSlice.reducer;