import axios, { AxiosResponse } from "axios";
import { checkLogin } from "./firebase";

const localhostURL = 'http://localhost:8000'
let baseURL = process.env.REACT_APP_NODE_BASE_URL + "";

export async function initWalletRechargeAPI(payload:{created_by:string,amount:number}) {
    let authToken = '';
    await checkLogin().then((res:any)=>{authToken = res.stsTokenManager.accessToken})

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/wallet/initRecharge`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer "+(authToken?authToken:'')
            },
            data: payload
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}

export async function getAllTransactionsAPI() {
    let authToken = '';
    await checkLogin().then((res: any) => { authToken = res.stsTokenManager.accessToken })

    return new Promise<{ response: AxiosResponse }>((resolve, reject) => {
        axios({
            url: `${baseURL}/wallet/getTransactions`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + (authToken ? authToken : '')
            }
        })
            .then((res) => {
                resolve({ response: res.data })
            })
            .catch((err) => {
                reject({ error: err.response.status })
            });

    });
}