import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddressPayload, SKUPayload, getAllStoreAddressesAPI, getAllCustomerAddressesAPI, createStoreAddressAPI, createCustomerAddressAPI, editCustomerAddressAPI, editStoreAddressAPI, createSKUAPI, editSKUAPI, getAllSKUAPI, getAllSupplierAddressesAPI, createSupplierAddressAPI, editSupplierAddressAPI } from 'app/api/general';
import { RootState } from '../store';

export interface GeneralState {
    storePage: number,
    storeTotalPages: number,
    storeAddresses: any[],
    customerPage: number,
    customerTotalPages: number,
    customerAddresses: any[],
    supplierPage: number,
    supplierTotalPages: number,
    supplierAddresses: any[],
    skuPage: number,
    skuTotalPages: number,
    sku: any[],
    fetchingStore: boolean,
    fetchingCustomer: boolean,
    fetchingSupplier: boolean,
    fetchingSKU: boolean,
}

const initialState: GeneralState = {
    storePage: 1,
    storeTotalPages: 1,
    storeAddresses: [],
    customerPage: 1,
    customerTotalPages: 1,
    customerAddresses: [],
    supplierPage: 1,
    supplierTotalPages: 1,
    supplierAddresses: [],
    skuPage: 1,
    skuTotalPages: 1,
    sku: [],
    fetchingStore: false,
    fetchingCustomer: false,
    fetchingSupplier: false,
    fetchingSKU: false
}

export const getAllStoreAddresses = createAsyncThunk(
    'general/getAllStoreAddresses',
    async (query:{page?: number, limit?: number, searchString?: string}) => {
        const response = await getAllStoreAddressesAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const getAllCustomerAddresses = createAsyncThunk(
    'general/getAllCustomerAddresses',
    async (query:{page?: number, limit?: number, searchString?: string}) => {
        const response = await getAllCustomerAddressesAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const getAllSupplierAddresses = createAsyncThunk(
    'general/getAllSupplierAddresses',
    async (query:{page?: number, limit?: number, searchString?: string}) => {
        const response = await getAllSupplierAddressesAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const getAllSKU = createAsyncThunk(
    'general/getAllSKU',
    async (query:{page?: number, limit?: number, searchString?: string}) => {
        const response = await getAllSKUAPI(query);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const createStoreAddress = createAsyncThunk(
    'general/createStoreAddress',
    async (data:AddressPayload) => {
        const response = await createStoreAddressAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const createCustomerAddress = createAsyncThunk(
    'general/createCustomerAddress',
    async (data:AddressPayload) => {
        const response = await createCustomerAddressAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const createSupplierAddress = createAsyncThunk(
    'general/createSupplierAddress',
    async (data:AddressPayload) => {
        const response = await createSupplierAddressAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const createSKU = createAsyncThunk(
    'general/createSKU',
    async (data:SKUPayload) => {
        const response = await createSKUAPI(data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const editStoreAddress = createAsyncThunk(
    'general/editStoreAddress',
    async ({id,data}:{id:string,data:AddressPayload}) => {
        const response = await editStoreAddressAPI(id,data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const editCustomerAddress = createAsyncThunk(
    'general/editCustomerAddress',
    async ({id,data}:{id:string,data:AddressPayload}) => {
        const response = await editCustomerAddressAPI(id,data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const editSupplierAddress = createAsyncThunk(
    'general/editSupplierAddress',
    async ({id,data}:{id:string,data:AddressPayload}) => {
        const response = await editSupplierAddressAPI(id,data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);
export const editSKU = createAsyncThunk(
    'general/editSKU',
    async ({id,data}:{id:string,data:SKUPayload}) => {
        const response = await editSKUAPI(id,data);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);


export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setCustomerPage: (state,action) => {
            state.customerPage = action.payload;
        },
        setStorePage: (state,action) => {
            state.storePage = action.payload;
        },
        setSKUPage: (state,action) => {
            state.skuPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllStoreAddresses.pending, (state) => {
            state.fetchingStore = true
        })
        .addCase(getAllStoreAddresses.fulfilled, (state, action) => {
            const { results, totalPages} = action.payload.data
            state.storeAddresses = results
            state.storeTotalPages = totalPages;
            state.fetchingStore = false
        })
        .addCase(getAllStoreAddresses.rejected, (state) => {
            state.fetchingStore = false
        })

        .addCase(getAllCustomerAddresses.pending, (state) => {
            state.fetchingCustomer = true
        })
        .addCase(getAllCustomerAddresses.fulfilled, (state, action) => {
            const { results, totalPages} = action.payload.data
            state.customerAddresses = results;
            state.customerTotalPages = totalPages;
            state.fetchingCustomer = false
        })
        .addCase(getAllCustomerAddresses.rejected, (state) => {
            state.fetchingCustomer = false
        })

        .addCase(getAllSupplierAddresses.pending, (state) => {
            state.fetchingSupplier = true
        })
        .addCase(getAllSupplierAddresses.fulfilled, (state, action) => {
            const { results, totalPages} = action.payload.data
            state.supplierAddresses = results;
            state.supplierTotalPages = totalPages;
            state.fetchingSupplier = false
        })
        .addCase(getAllSupplierAddresses.rejected, (state) => {
            state.fetchingSupplier = false
        })

        .addCase(getAllSKU.pending, (state) => {
            state.fetchingSKU = true
        })
        .addCase(getAllSKU.fulfilled, (state, action) => {
            const { results, totalPages} = action.payload.data
            state.sku = results;
            state.skuTotalPages = totalPages;
            state.fetchingSKU = false
        })
        .addCase(getAllSKU.rejected, (state) => {
            state.fetchingSKU = false
        })

        .addCase(createStoreAddress.pending, (state) => {
            state.fetchingStore = true
        })
        .addCase(createStoreAddress.fulfilled, (state, action) => {
            let storeAddresses = state.storeAddresses
            storeAddresses.unshift(action.payload.data)
            state.storeAddresses = storeAddresses
            state.fetchingStore = false
        })
        .addCase(createStoreAddress.rejected, (state) => {
            state.fetchingStore = false
        })

        .addCase(createSupplierAddress.pending, (state) => {
            state.fetchingSupplier = true
        })
        .addCase(createSupplierAddress.fulfilled, (state, action) => {
            let supplierAddresses = state.supplierAddresses
            supplierAddresses.unshift(action.payload.data)
            state.supplierAddresses = supplierAddresses
            state.fetchingSupplier = false
        })
        .addCase(createSupplierAddress.rejected, (state) => {
            state.fetchingSupplier = false
        })

        .addCase(createCustomerAddress.pending, (state) => {
            state.fetchingCustomer = true
        })
        .addCase(createCustomerAddress.fulfilled, (state, action) => {
            let customerAddresses = state.customerAddresses
            customerAddresses.unshift(action.payload.data)
            state.customerAddresses = customerAddresses
            state.fetchingCustomer = false
        })
        .addCase(createCustomerAddress.rejected, (state) => {
            state.fetchingCustomer = false
        })

        .addCase(createSKU.pending, (state) => {
            state.fetchingSKU = true
        })
        .addCase(createSKU.fulfilled, (state, action) => {
            let sku = state.sku
            sku.unshift(action.payload.data)
            state.sku = sku
            state.fetchingSKU = false
        })
        .addCase(createSKU.rejected, (state) => {
            state.fetchingSKU = false
        })

        .addCase(editStoreAddress.pending, (state) => {
            state.fetchingStore = true
        })
        .addCase(editStoreAddress.fulfilled, (state, action) => {
            const { data } = action.payload;
            let prevAddresses = state.storeAddresses
            for (let index = 0; index < prevAddresses.length; index++) {
                if(prevAddresses[index]._id===data._id){
                    prevAddresses[index] = data;
                    break;
                }
            }
            state.customerAddresses = prevAddresses;
            state.fetchingStore = false
        })
        .addCase(editStoreAddress.rejected, (state) => {
            state.fetchingStore = false
        })

        .addCase(editCustomerAddress.pending, (state) => {
            state.fetchingStore = true
        })
        .addCase(editCustomerAddress.fulfilled, (state, action) => {
            const { data } = action.payload;
            let prevAddresses = state.customerAddresses
            for (let index = 0; index < prevAddresses.length; index++) {
                if(prevAddresses[index]._id===data._id){
                    prevAddresses[index] = data;
                    break;
                }
            }
            state.customerAddresses = prevAddresses;
            state.fetchingStore = false
        })
        .addCase(editCustomerAddress.rejected, (state) => {
            state.fetchingStore = false
        })

        .addCase(editSupplierAddress.pending, (state) => {
            state.fetchingSupplier = true
        })
        .addCase(editSupplierAddress.fulfilled, (state, action) => {
            const { data } = action.payload;
            let prevAddresses = state.supplierAddresses
            for (let index = 0; index < prevAddresses.length; index++) {
                if(prevAddresses[index]._id===data._id){
                    prevAddresses[index] = data;
                    break;
                }
            }
            state.supplierAddresses = prevAddresses;
            state.fetchingSupplier = false
        })
        .addCase(editSupplierAddress.rejected, (state) => {
            state.fetchingSupplier = false
        })

        .addCase(editSKU.pending, (state) => {
            state.fetchingSKU = true
        })
        .addCase(editSKU.fulfilled, (state, action) => {
            const { data } = action.payload;
            let sku = state.sku
            for (let index = 0; index < sku.length; index++) {
                if(sku[index]._id===data._id){
                    sku[index] = data;
                    break;
                }
            }
            state.customerAddresses = sku;
            state.fetchingSKU = false
        })
        .addCase(editSKU.rejected, (state) => {
            state.fetchingSKU = false
        })
    }
})

export const { setCustomerPage, setStorePage, setSKUPage } = generalSlice.actions

export const getStorePageState = (state: RootState) => state.general.storePage
export const getStoreAddressesState = (state: RootState) => state.general.storeAddresses
export const getStoreTotalPagesState = (state: RootState) => state.general.storeTotalPages

export const getCustomersPageState = (state: RootState) => state.general.customerPage
export const getCustomersAddressesState = (state: RootState) => state.general.customerAddresses
export const getCustomersTotalPagesState = (state: RootState) => state.general.customerTotalPages

export const getSupplierPageState = (state: RootState) => state.general.supplierPage
export const getSupplierAddressesState = (state: RootState) => state.general.supplierAddresses
export const getSupplierTotalPagesState = (state: RootState) => state.general.supplierTotalPages

export const getCustomersSKUPageState = (state: RootState) => state.general.skuPage
export const getCustomersSKUState = (state: RootState) => state.general.sku
export const getCustomersSKUTotalPagesState = (state: RootState) => state.general.skuTotalPages

export const getFetchingStoreState = (state: RootState) => state.general.fetchingStore
export const getFetchingCustomerState = (state: RootState) => state.general.fetchingCustomer
export const getFetchingSKUState = (state: RootState) => state.general.fetchingSKU

export default generalSlice.reducer;