import { Box, Button, FormControl, FormLabel, Input, InputGroup, Text, InputRightElement, List, Select, useColorModeValue, InputLeftElement } from '@chakra-ui/react';
import React, { useState } from 'react';
import {render} from 'react-dom'
import Downshift from 'downshift'
import { FaBuilding, FaCalendarAlt, FaChevronDown, FaChevronUp, FaLocationArrow, FaPlus, FaSearch } from 'react-icons/fa';
import { HSeparator } from 'components/separator/Separator';
import { IoPricetags } from 'react-icons/io5';
import RangeCalendar from 'components/calendar/RangeCalendar';
import { OnDateSelected } from 'types/commonTypes';
import { format } from 'util';

export default function DateRangePicker(props:{selectedDates:any, setSelectedDates:Function, placeHolder?:string, disabled?:boolean, icon?:any}) {
	const { selectedDates, setSelectedDates, placeHolder, disabled, icon } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgColor = useColorModeValue('white', '#111c44');
	const bgColorBtn = useColorModeValue('white', '#253368');
	const borderColor = useColorModeValue('rgba(0,0,0,0.1)', 'white300');

    const today = new Date();

    const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
        let newDates = [...selectedDates];
        if (selectedDates.length) {
        if (selectedDates.length === 1) {
            let firstTime = selectedDates[0];
            if (firstTime < date) {
            newDates.push(date);
            } else {
            newDates.unshift(date);
            }
            setSelectedDates(newDates);
            return;
        }

        if (newDates.length === 2) {
            setSelectedDates([date]);
            return;
        }
        } else {
        newDates.push(date);
        setSelectedDates(newDates);
        }
    };

    // eventually we want to allow user to freely type their own input and parse the input
    let intVal = selectedDates[0]
    ? `${selectedDates[0].toLocaleString().split(',')[0]}`
    : '';
    intVal += selectedDates[1]
    ? ` - ${selectedDates[1].toLocaleString().split(',')[0]}`
    : '';


    return(
        <Downshift
          onChange={selection => {
            }
          }
          itemToString={item => (item ? item.sku_name : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            getToggleButtonProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
          }) => (
                <>
                <div style={{display: 'inline-block', width:'100%'}} {...getRootProps(undefined, {suppressRefError: true})}>

                    <Button
                        leftIcon={icon?icon:<FaCalendarAlt/>} borderRadius='0px'
                        rightIcon={(isOpen && !disabled)?<FaChevronUp/>:<FaChevronDown />}
                        disabled={disabled} size='sm'  variant='outline'
                        {...getToggleButtonProps()} isFullWidth fontWeight={'normal'}
                        color={textColor} backgroundColor={bgColor}
                        textTransform='capitalize'
                    >
                        <Text textAlign='start' flex={1}>{intVal?intVal:placeHolder?placeHolder:"Select Date Range"}</Text>
                    </Button>
                </div>
                <Box
                    display={(isOpen && !disabled)?'flex':'none'}
                    position='absolute'
                    zIndex={999}
                    bg={bgColor}
                    borderRadius='4px'
                    border={isOpen?'1px solid gray':undefined}
                    borderColor={borderColor}
                    boxShadow='6px 5px 8px rgba(0,50,30,0.02)'
                    p='10px'
                    onMouseDown={e=>{e.preventDefault();e.stopPropagation()}}
                >
                    <Box display='block' w='120px' pr='10px'>
                        <Button
                            size='sm' my='3px' isFullWidth variant='brand'
                            onClick={()=>{setSelectedDates([today,today])}}
                        >
                            Today
                        </Button>
                        <Button
                            size='sm' my='3px' isFullWidth variant='brand'
                            onClick={()=>{
                                var first = today.getDate() - today.getDay() + 1; // First day is the day of the month - the day of the week
                                var last = first + 6; // last day is the first day + 6
                                
                                setSelectedDates([new Date(today.setDate(first)),new Date(today.setDate(last))])
                            }}
                        >
                            This Week
                        </Button>
                        <Button
                            size='sm' my='3px' isFullWidth variant='brand'
                            onClick={()=>{
                                const y = today.getFullYear(), m = today.getMonth();
                                const firstDay = new Date(y, m, 2);
                                setSelectedDates([firstDay,today]);
                            }}
                        >
                            This Month
                        </Button>
                        <Button
                            size='sm' my='3px' isFullWidth variant='brand'
                            onClick={()=>{
                                const dateLM = new Date(), yLM = dateLM.getFullYear(), mLM = dateLM.getMonth();
                                const firstDayLM = new Date(yLM, mLM - 1, 1);
                                const lastDayLM = new Date(yLM, mLM, 0);
                                setSelectedDates([firstDayLM,lastDayLM]);
                            }}
                        >
                            Last Month
                        </Button>
                        <Button
                            size='sm' my='3px' isFullWidth colorScheme='red'
                            onClick={()=>{setSelectedDates([])}}
                        >
                            Clear
                        </Button>
                    </Box>
                    <RangeCalendar selectedDates={selectedDates} handleOnDateSelected={handleOnDateSelected}/>
                </Box>
            </>
          )}
        </Downshift>
        )
}
