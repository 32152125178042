import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAgentOrdersAPI, getAllRequestsAPI, getAllOrdersAPI, getSingleIntOrderAPI, updateAgentOrderAPI, updateIntOrderAPI } from 'app/api/orders';
import { RootState } from '../store';

export interface OrderState {
    allOrders: any[],
    allAgentOrders: any[],
    totalPages: number,
    allRequests: any[],
    totalReqPages: number,
    agentOrderDetails: any,
    fetching: boolean,
}

const initialState: OrderState = {
    allOrders: [],
    allAgentOrders: [],
    totalPages: 1,
    allRequests: [],
    totalReqPages: 1,
    agentOrderDetails: undefined,
    fetching: false,
}

export const getAllOrders = createAsyncThunk(
    'orders/getAllIntOrders',
    async () => {
        const response = await getAllOrdersAPI();
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getSingleIntOrder = createAsyncThunk(
    'orders/getSingleIntOrder',
    async (orderID:string) => {
        const response = await getSingleIntOrderAPI(orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateIntOrder = createAsyncThunk(
    'orders/updateIntOrder',
    async ({payload, orderID}:{payload:any, orderID:string}) => {
        const response = await updateIntOrderAPI(payload,orderID);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllAgentOrders = createAsyncThunk(
    'orders/getAllAgentOrders',
    async (params:{orderStatus?:string, orderType?:string, page?:number, limit?:number, searchString?:string}) => {
        const response = await getAllAgentOrdersAPI(params);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const getAllAgentRequests = createAsyncThunk(
    'orders/getAllAgentRequests',
    async (params:{ page?:number, limit?:number }) => {
        const response = await getAllRequestsAPI(params);
        // The value we return becomes the `fulfilled` action payload
        return response.response;
    }
);

export const updateAgentOrder = createAsyncThunk(
    'orders/updateAgentOrder',
    async ({payload, orderID, pop}:{payload:any, orderID:string, pop?:boolean}) => {
        const response = await updateAgentOrderAPI(payload,orderID);
        // The value we return becomes the `fulfilled` action payload
        return {...response.response, pop: pop};
    }
);

export const generalSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        updateAgentNewOrder: (state, action) => {
            let tempOrders = state.allAgentOrders
            const {orderData,pop} = action.payload;
            for (let i = 0; i < tempOrders.length; i++) {
                const order = tempOrders[i];
                if (order.sm_orderid === orderData.sm_orderid) {
                    if(pop){
                        tempOrders.splice(i,1);
                    }else{
                        tempOrders[i] = orderData;
                    }
                    break
                }
            }
            state.allAgentOrders = tempOrders
            state.agentOrderDetails = orderData
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllOrders.pending, (state) => {
                state.fetching = true
            })
            .addCase(getAllOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                state.allOrders = data;
            })
            .addCase(getAllOrders.rejected, (state) => {
                state.fetching = false
            })
            
            .addCase(getAllAgentOrders.pending, (state) => {
                state.fetching = true
                state.allAgentOrders = [];
            })
            .addCase(getAllAgentOrders.fulfilled, (state, action) => {
                state.fetching = false
                const { results, totalPages } = action.payload.data;
                state.allAgentOrders = results;
                state.totalPages = totalPages||1
            })
            .addCase(getAllAgentOrders.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getAllAgentRequests.pending, (state) => {
                state.fetching = true
                state.allRequests = [];
            })
            .addCase(getAllAgentRequests.fulfilled, (state, action) => {
                state.fetching = false
                const { results, totalPages } = action.payload.data;
                state.allRequests = results;
                state.totalPages = totalPages||1
            })
            .addCase(getAllAgentRequests.rejected, (state) => {
                state.fetching = false
            })

            .addCase(getSingleIntOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(getSingleIntOrder.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                let prevOrders = state.allOrders
                for (let index = 0; index < prevOrders.length; index++) {
                    if(prevOrders[index]._id===data._id){
                        prevOrders[index] = data;
                        break;
                    }
                }
            })
            .addCase(getSingleIntOrder.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateIntOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateIntOrder.fulfilled, (state, action) => {
                state.fetching = false
                const { data } = action.payload;
                let prevOrders = state.allOrders
                for (let index = 0; index < prevOrders.length; index++) {
                    if(prevOrders[index]._id===data._id){
                        prevOrders[index] = data;
                        break;
                    }
                }
                state.allOrders = prevOrders;
            })
            .addCase(updateIntOrder.rejected, (state) => {
                state.fetching = false
            })

            .addCase(updateAgentOrder.pending, (state) => {
                state.fetching = true
            })
            .addCase(updateAgentOrder.fulfilled, (state, action) => {
                state.fetching = false
                const { data, pop } = action.payload;
                let prevOrders = state.allAgentOrders
                for (let index = 0; index < prevOrders.length; index++) {
                    if(prevOrders[index]._id===data._id){
                        if(pop){
                            prevOrders.splice(index,1);
                        }else{
                            prevOrders[index] = data;
                        }
                        break;
                    }
                }
                state.allAgentOrders = prevOrders;
            })
            .addCase(updateAgentOrder.rejected, (state) => {
                state.fetching = false
            })

    }
})


export const { updateAgentNewOrder } = generalSlice.actions

export const getAllOrdersState = (state: RootState) => state.apsrtc.allOrders;

export const getAllAgentOrdersState = (state: RootState) => state.apsrtc.allAgentOrders;
export const getAllAgentOrdersTotalPagesState = (state: RootState) => state.apsrtc.totalPages;

export const getAllAgentRequestsState = (state: RootState) => state.apsrtc.allRequests;

export const getOrdersFetching = (state: RootState) => state.apsrtc.fetching

export default generalSlice.reducer;