import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import authReducer from 'app/reducer/auth.reducer';
import generalReducer from 'app/reducer/general.reducer';
import hyperlocalReducer from 'app/reducer/hyperlocal.reducer';
import panindiaReducer from 'app/reducer/panindia.reducer';
import apsrtcReducer from 'app/reducer/apsrtc.reducer';
import walletReducer from 'app/reducer/wallet.reducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    general: generalReducer,
    hyperlocal: hyperlocalReducer,
    panindia: panindiaReducer,
    apsrtc: apsrtcReducer,
    wallet: walletReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['order/createOrder/fulfilled']
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;